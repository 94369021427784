import _toConsumableArray from "/home/circleci/src/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _defineProperty from "/home/circleci/src/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _objectWithoutProperties from "/home/circleci/src/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

/*
 * @format
 *
 * This is a generator for a reducer that follows the pending state pattern we've prototyped.
 * Reported state is stored separately.
 * Users desired changes are stored here when the user makes a change.
 * The selector picks the pending state, if that doesn't exist it uses the real one
 *
 * When we receive an rejection or timeout
 * - We add an error to the array (and the ui will pop a modal)
 * - The pending value is removed
 *
 * When we receive a completion
 * - update the request id to 'done' so other updates won't match
 * - but it can be read by the selectors
 *
 * When we get a Live message
 * - Clear out requests that are 'done'.
 *
 */
import * as actionTypes from '../action-types';
/*
const examle = {
  requests: [
    {
      zoneDeviceId: 'prt-123',
      endpointId: 0,
      requestId: 'abcd-efgh-1234-5678',
      request: {
        setpoint: 2000,
      },
    },
  ],
  errors: [
    {
      zoneDeviceId: 'prt-123',
      endpointId: 0,
      requestId: 'abcd-efgh-1234-5678',
      message: 'There was an error',
    },
  ],
};
*/

export var initalState = {
  requests: [],
  errors: []
};

var matchingZoneId = function matchingZoneId(request, zoneDeviceId, endpointId, prtSetting) {
  return request.zoneDeviceId === zoneDeviceId && request.endpointId === endpointId && request.prtSetting === prtSetting;
};

export default (function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initalState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  // split the type value off
  var type = action.type,
      actionData = _objectWithoutProperties(action, ["type"]);

  switch (type) {
    case actionTypes.PRT_SETTINGS_UPDATE_STARTED:
      {
        // split manditory and data values
        var homeId = actionData.homeId,
            zoneId = actionData.zoneId,
            requestId = actionData.requestId,
            prtSetting = actionData.prtSetting,
            value = actionData.value;
        return _objectSpread(_objectSpread({}, state), {}, {
          requests: [].concat(_toConsumableArray(state.requests.filter(function (request) {
            return !matchingZoneId(request, zoneId[0], zoneId[1], prtSetting);
          })), [// add a new one
          {
            homeId: homeId,
            zoneDeviceId: zoneId[0],
            endpointId: zoneId[1],
            requestId: requestId,
            prtSetting: prtSetting,
            value: value
          }])
        });
      }

    case actionTypes.SET_ZONE_NAME_STARTED:
      {
        var _prtSetting = 'zoneName';
        var _homeId = actionData.homeId,
            _zoneId = actionData.zoneId,
            _requestId = actionData.requestId,
            zoneName = actionData.zoneName;
        return _objectSpread(_objectSpread({}, state), {}, {
          requests: [].concat(_toConsumableArray(state.requests.filter(function (request) {
            return !matchingZoneId(request, _zoneId[0], _zoneId[1], _prtSetting);
          })), [{
            homeId: _homeId,
            zoneDeviceId: _zoneId[0],
            endpointId: _zoneId[1],
            requestId: _requestId,
            prtSetting: _prtSetting,
            value: zoneName
          }])
        });
      }

    case actionTypes.SET_ZONE_NAME_RECEIVED:
    case actionTypes.WEBSOCKET_PRT_SETTINGS_UPDATE_RECEIVED:
      {
        var _requestId2 = actionData.requestId || actionData.data.requestId; // remove entry if it exists


        var entry = state.requests.find(function (r) {
          return r.requestId === _requestId2;
        });
        console.log(entry);

        if (entry) {
          return _objectSpread(_objectSpread({}, state), {}, {
            requests: [].concat(_toConsumableArray(state.requests.filter(function (request) {
              return request.requestId !== _requestId2;
            })), [_objectSpread(_objectSpread({}, entry), {}, {
              requestId: 'done'
            })])
          });
        }

        break;
      }

    case actionTypes.WEBSOCKET_PRT_SETTINGS_UPDATE_REJECTED:
    case actionTypes.PENDING_CHANGE_TIMED_OUT:
      {
        // find request,
        // remove it,
        // if it was removed, add an error
        var _requestId3 = actionData.requestId || actionData.data.requestId;

        var _entry = state.requests.find(function (request) {
          return request.requestId === _requestId3;
        });

        if (_entry) {
          var message = type === actionTypes.WEBSOCKET_PRT_SETTINGS_UPDATE_REJECTED ? "Settings update rejected" : "Settings update timed out";
          return _objectSpread(_objectSpread({}, state), {}, {
            requests: _toConsumableArray(state.requests.filter(function (request) {
              return request.requestId !== _requestId3;
            })),
            errors: [].concat(_toConsumableArray(state.errors), [{
              requestId: _requestId3,
              raised: Date.now(),
              zoneDeviceId: _entry.zoneDeviceId,
              endpointId: _entry.endpointId,
              message: message
            }])
          });
        }

        break;
      }
    // don't add errors for set zone name rejection/timeout as
    // the failure case is handled in the zone-naming-reducer

    case actionTypes.SET_ZONE_NAME_REJECTED:
    case actionTypes.SET_ZONE_NAME_TIMEOUT:
      {
        console.log(action);

        var _requestId4 = actionData.requestId || actionData.data.requestId;

        var _entry2 = state.requests.find(function (request) {
          return request.requestId === _requestId4;
        });

        if (_entry2) {
          return _objectSpread(_objectSpread({}, state), {}, {
            requests: _toConsumableArray(state.requests.filter(function (request) {
              return request.requestId !== _requestId4;
            }))
          });
        }

        break;
      }

    case actionTypes.WEBSOCKET_LIVE_RECEIVED:
      {
        // remove all done states
        return _objectSpread(_objectSpread({}, state), {}, {
          requests: _toConsumableArray(state.requests.filter(function (request) {
            return request.requestId !== 'done';
          }))
        });
      }

    case actionTypes.DISMISS_ERROR:
      {
        var _requestId5 = actionData.requestId;
        return _objectSpread(_objectSpread({}, state), {}, {
          errors: state.errors.filter(function (request) {
            return request.requestId !== _requestId5;
          })
        });
      }

    default:
      break;
  }

  return state;
});
export var getPendingSettingsData = function getPendingSettingsData(state, zoneId, prtSetting) {
  if (state) {
    var existingRequests = state.requests.filter(function (r) {
      return r.zoneDeviceId === zoneId[0] && r.prtSetting === prtSetting;
    });

    if (existingRequests.length > 0) {
      return existingRequests[existingRequests.length - 1];
    }
  }

  return undefined;
};
export var getPendingError = function getPendingError(state) {
  return state.errors[0];
};