/*
 * @format
 */
export var AUTO_MODE = 'Auto';
export var MANUAL_MODE = 'Manual';
export var ON_MODE = 'On';
export var OFF_MODE = 'Off';
export var OVERRIDE_NEXT_MODE = 'OverrideNext';
export var OVERRIDE_PLUS_HOURS_MODE = 'OverridePlus';
export var MAX_SETPOINT = 3000;
export var MODES = {
  Auto: AUTO_MODE,
  Manual: MANUAL_MODE,
  On: ON_MODE,
  Off: OFF_MODE,
  OverrideNext: OVERRIDE_NEXT_MODE,
  OverridePlusHours: OVERRIDE_PLUS_HOURS_MODE
};
export var DAYS_OF_WEEK = {
  MONDAY: 'Monday',
  TUESDAY: 'Tuesday',
  WEDNESDAY: 'Wednesday',
  THURSDAY: 'Thursday',
  FRIDAY: 'Friday',
  SATURDAY: 'Saturday',
  SUNDAY: 'Sunday'
};
export var COGNITO_EMAIL = 'identity@idealcloudservices.com';
export var isValidMode = function isValidMode(mode) {
  return mode === MODES.Auto || mode === MODES.Manual || mode === MODES.Off || mode === MODES.OverrideNext || mode === MODES.OverridePlusHours;
};
export var MINUTES_SINCE_SUNDAY = {
  Sunday: 0,
  Monday: 1440,
  Tuesday: 2880,
  Wednesday: 4320,
  Thursday: 5760,
  Friday: 7200,
  Saturday: 8640
};
export var DEVICE_TYPES = {
  Boiler: 'Boiler',
  Si: 'Smart Interface',
  Prt: 'Halo',
  ZigbeeRepeater: 'Zigbee Booster',
  Swc: 'Smart Wiring Centre'
};
export var PRT_SETTINGS = {
  optimisedStartEnabled: 'optimisedStartEnabled',
  optimisedStopEndabled: 'optimisedStopEndabled',
  delayedStartEnabled: 'delayedStartEnabled',
  childLockOn: 'childLockOn',
  displayTolerance: 'displayToleranceDot1',
  temperatureOffset: 'temperatureOffset',
  timeFormat: 'timeFormat',
  frostProtectionSetpoint: 'frostProtectionSetpoint'
};
export var IDEAL_SUPPORT_NUMBER = '01482498221';