import React, { Component } from "react";
import { Provider } from "react-redux";

import store from "./store";

import "./App.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import RedirectToForgotPasswordDeepLink from "./components/RedirectToForgotPasswordDeepLink";
import Main from "./Main";
import Policy from "./screens/Policy";

class App extends Component {
  render() {
    return (
      <div className="App">
        <Provider store={store}>
          <Router>
            <div>
              <Route
                path={"/password-code"}
                component={() => <RedirectToForgotPasswordDeepLink />}
              />
              <Route
                path="/privacy-policy"
                exact
                component={() => <Policy />}
              />
              <Route path="/" exact component={() => <Main />} />
            </div>
          </Router>
        </Provider>
      </div>
    );
  }
}

export default App;
