/*
 * @format
 *
 * Functions to translate state values into
 * values to display in the app
 */
import { DEVICE_TYPES } from './utils/consts';
var attributeMap = {
  boiler: DEVICE_TYPES.Boiler,
  si: DEVICE_TYPES.Si,
  prt: DEVICE_TYPES.Prt,
  zigbeeRepeater: DEVICE_TYPES.ZigbeeRepeater,
  swc: DEVICE_TYPES.Swc,
  wifi: 'Wi-Fi',
  zigbee: 'Zigbee',
  poor: 'Poor',
  good: 'Good',
  excellent: 'Excellent',
  high: 'OK',
  low: 'Low',
  disconnected: 'Disconnected',
  unknown: 'Unknown'
};

var map = function map(attribute) {
  return attributeMap[attribute] ? attributeMap[attribute] : attribute;
};

export var presentDeviceType = function presentDeviceType(type) {
  return map(type) || type;
};
export var presentDeviceConnectionType = function presentDeviceConnectionType(connectionType) {
  return map(connectionType) || connectionType;
};
export var presentSiConnectionStrength = function presentSiConnectionStrength(isSiConnected, siConnectionStrength) {
  if (!isSiConnected) {
    return 'Unknown';
  }

  return map(siConnectionStrength);
};
export var presentPrtConnectionStrength = function presentPrtConnectionStrength(isSiConnected, isPrtConnected, prtConnectionStrength) {
  if (!isSiConnected) {
    return 'Unknown';
  }

  if (!isPrtConnected) {
    return 'Disconnected';
  }

  return map(prtConnectionStrength);
};
export var presentPrtBatteryLevel = function presentPrtBatteryLevel(isSiConnected, isPrtConnected, prtBatteryLevel) {
  if (!isSiConnected || !isPrtConnected) {
    return 'Unknown';
  }

  return map(prtBatteryLevel);
};