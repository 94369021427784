import React from 'react';
import Button from './Button';

export default function PlusHoursOptions({onSelect, onClose}) {
  return (
    <div>
      <Button onPress={() => onSelect(60)}>1 Hour</Button>
      <Button onPress={() => onSelect(120)}>2 Hours</Button>
      <Button onPress={() => onSelect(180)}>3 Hours</Button>
      <Button onPress={() => onSelect(240)}>4 Hours</Button>
      <Button onPress={() => onSelect(300)}>5 Hours</Button>
      <Button onPress={() => onSelect(360)}>6 Hours</Button>
      <Button onPress={onClose}>Dismiss</Button>
    </div>
  )
};
