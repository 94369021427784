import _defineProperty from "/home/circleci/src/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

/*
 * @format
 */
import moment from 'moment';
import { MODES } from '../utils/consts';
import { isMatchingZoneId, humanizeTemperature, getFollowingDay, getPreviousDay, getDayOfWeek, dateTimeToMinutesSinceMidnight, humanizeTimeTwelveHour } from '../utils/helpers';
import { getTimeDisplayFormat } from '../selectors';
import { getPlusHoursEndtime } from '../controls-selectors';
import { getEventProgress as scheduleGetEventProgress, getNextEvent as scheduleGetNextEvent, getCurrentEvent as scheduleGetCurrentEvent, flattenSchedule, weekifySchedule, unflattenSchedule } from '../schedule/schedule';

var getZonePropertyOrDefault = function getZonePropertyOrDefault(state, homeId, zoneId, property, defaultValue) {
  var home = state.homeList.homes.find(function (x) {
    return x.id === homeId;
  });

  if (!home) {
    return defaultValue;
  }

  var zone = home.zones.find(function (x) {
    return isMatchingZoneId(x.zoneId, zoneId);
  });

  if (!zone) {
    return defaultValue;
  }

  return zone[property];
}; // this is written on the assumption that we will only ever have one schedule request
// at once, which is true because the UI locks up until we get an acceptance


export var getPendingSchedule = function getPendingSchedule(state, unflatten, zoneId) {
  if (state.schedule && state.schedule.request && state.schedule.request !== 'Timed out' && state.schedule.request !== 'rejected') {
    if (state.schedule.request.zoneId !== zoneId) {
      return null;
    }

    if (unflatten) {
      return unflattenSchedule(state.schedule.request.schedule).map(function (x) {
        return _objectSpread(_objectSpread({}, x), {}, {
          events: x.events.map(function (y, i) {
            return _objectSpread(_objectSpread({}, y), {}, {
              endTime: i + 1 < x.events.length ? x.events[i + 1].startTime : undefined
            });
          })
        });
      });
    }

    return state.schedule.request.schedule;
  }

  return undefined;
};

var getWeekifiedSchedule = function getWeekifiedSchedule(state, homeId, zoneId) {
  var zoneSchedule = getZonePropertyOrDefault(state, homeId, zoneId, 'schedule', []);
  var pendingSchedule = getPendingSchedule(state, true, zoneId);
  var schedule = pendingSchedule || zoneSchedule;
  return weekifySchedule(schedule);
};

export var getCurrentEvent = function getCurrentEvent(state, homeId, zoneId) {
  var schedule = getWeekifiedSchedule(state, homeId, zoneId);
  return scheduleGetCurrentEvent(schedule);
};
export var getNextEvent = function getNextEvent(state, homeId, zoneId) {
  var schedule = getWeekifiedSchedule(state, homeId, zoneId);
  var plusHoursEndtime = getPlusHoursEndtime(state, homeId, zoneId);
  var timeDisplayFormat = getTimeDisplayFormat(state, homeId, zoneId);
  var nextEvent = scheduleGetNextEvent(schedule, plusHoursEndtime, timeDisplayFormat);
  return nextEvent;
};
export var getEventProgress = function getEventProgress(state, homeId, zoneId) {
  var schedule = getWeekifiedSchedule(state, homeId, zoneId);
  return scheduleGetEventProgress(schedule);
};
/* ============================================================

  Schedule selectors taken from selectors.js - some may be redundant

============================================================ */

export var getNextScheduleEvent = function getNextScheduleEvent(state, date, homeId, zoneId) {
  var nextEvent = getNextEvent(state, homeId, zoneId);

  if (nextEvent != null) {
    if (!nextEvent.setpoint) {
      return "".concat(!nextEvent.on ? 'On' : 'Off', " at ").concat(nextEvent.displayTime);
    }

    return "".concat(humanizeTemperature(nextEvent.setpoint, true, false, true), " at ").concat(nextEvent.displayTime);
  }

  return 'No more events today';
};
export var getNextEventText = function getNextEventText(state, homeId, zoneId, mode, isInHolidayMode) {
  if (mode === MODES.Off || isInHolidayMode) {
    return 'Schedule Inactive ';
  }

  if (mode === MODES.Auto || mode === MODES.OverrideNext || mode === MODES.OverridePlusHours) {
    var date = new Date();
    var next = getNextScheduleEvent(state, date, homeId, zoneId);
    return next;
  }

  if (mode === MODES.Manual) {
    return 'Permanently On';
  }

  return 'Mode Unknown';
};
export var getSchedule = function getSchedule(state, homeId, zoneId) {
  var home = state.homeList.homes.find(function (x) {
    return x.id === homeId;
  });
  var selectedZone = home.zones.find(function (x) {
    return isMatchingZoneId(x.zoneId, zoneId);
  });

  if (!selectedZone) {
    return [];
  }

  var pendingSchedule = getPendingSchedule(state, false);
  return pendingSchedule || flattenSchedule(selectedZone.schedule);
};
export var getScheduleEvents = function getScheduleEvents(state, homeId, zoneId, selectedDay) {
  var home = state.homeList.homes.find(function (x) {
    return x.id === homeId;
  });

  if (!home) {
    return [];
  }

  var zone = home.zones.find(function (x) {
    return isMatchingZoneId(x.zoneId, zoneId);
  });

  if (!zone.schedule) {
    return [];
  }

  var schedule = getPendingSchedule(state, true) || zone.schedule;
  var todaysSchedule = schedule.find(function (x) {
    return x.day === selectedDay;
  });

  if (!todaysSchedule) {
    return [];
  }

  var followingDay = getFollowingDay(todaysSchedule.day);
  var nextSchedule = schedule.find(function (x) {
    return x.day === followingDay;
  });

  if (nextSchedule && nextSchedule.events.length > 0) {
    todaysSchedule.events[todaysSchedule.events.length - 1].endTime = nextSchedule.events[0].startTime + 1440;
  }

  return todaysSchedule ? todaysSchedule.events : [];
};
export var getCurrentScheduleEventValue = function getCurrentScheduleEventValue(state, homeId, zoneId, date) {
  var day = getDayOfWeek(date);
  var schedule = getScheduleEvents(state, homeId, zoneId, day);
  var minsSinceMidnight = dateTimeToMinutesSinceMidnight(date);
  var nextEventEndTime = null; // for normal events

  for (var count = 0; count < schedule.length - 1; count += 1) {
    if (schedule[count].startTime < minsSinceMidnight && schedule[count + 1].startTime > minsSinceMidnight) {
      nextEventEndTime = schedule[count + 1].startTime;
      break;
    }
  } // for events that start the day before


  if (nextEventEndTime == null && schedule[0]) {
    if (minsSinceMidnight < schedule[0].startTime) {
      nextEventEndTime = schedule[0].startTime;
    }
  } // this function returns null if not true, the 'getCurrentScheduleEvent'
  // method is useful if you're looking at ways to implement


  return nextEventEndTime;
};
export var getCurrentScheduleEvent = function getCurrentScheduleEvent(state, homeId, zoneId, date) {
  var overrideState = getPlusHoursEndtime(state, homeId, zoneId);

  if (overrideState !== null) {
    // we're overriding, so return override time
    return moment(overrideState).format('hh:mm a');
  }

  var nextEventEndTime = getCurrentScheduleEventValue(state, homeId, zoneId, date);

  if (nextEventEndTime != null) {
    return humanizeTimeTwelveHour(nextEventEndTime, false);
  }

  return undefined;
};
export var getNextScheduleEventValue = function getNextScheduleEventValue(state, date, homeId, zoneId) {
  var overrideState = getPlusHoursEndtime(state, homeId, zoneId);
  var thisDate = overrideState || date;

  if (!thisDate) {
    return null;
  }

  var day = moment(thisDate).format('dddd');
  var schedule = getScheduleEvents(state, homeId, zoneId, day);

  if (overrideState) {
    var overrideMinsSinceMidnight = dateTimeToMinutesSinceMidnight(overrideState);
    var scheduleEvent = schedule.find(function (x) {
      return x.startTime <= overrideMinsSinceMidnight && x.endTime >= overrideMinsSinceMidnight;
    });

    if (!scheduleEvent) {
      return {
        setpoint: 0,
        startTime: overrideMinsSinceMidnight
      };
    }

    return {
      setpoint: scheduleEvent.setpoint,
      startTime: overrideMinsSinceMidnight
    };
  }

  var nextEvent = null;
  var minsSinceMidnight = dateTimeToMinutesSinceMidnight(date); // check all events

  for (var count = 0; count < schedule.length; count += 1) {
    if (schedule[count].startTime > minsSinceMidnight) {
      nextEvent = schedule[count];
      break;
    }
  } // this function returns null if not true, the 'getNextScheduleEvent'
  // method is useful if you're looking at ways to implement


  return nextEvent;
};
export var getSelectedScheduleDay = function getSelectedScheduleDay(state) {
  return state.schedule.selectedScheduleDay;
};
export var getCurrentScheduleFullEvent = function getCurrentScheduleFullEvent(state, homeId, zoneId, date) {
  var day = getDayOfWeek(date);
  var minsSinceMidnight = dateTimeToMinutesSinceMidnight(date);
  var schedule = getScheduleEvents(state, homeId, zoneId, day);
  var earlier = schedule.filter(function (e) {
    return e.startTime < minsSinceMidnight;
  });
  var result = earlier[earlier.length - 1];

  if (result) {
    return result;
  }

  var dayBefore = getPreviousDay(day);
  var scheduleBefore = getScheduleEvents(state, homeId, zoneId, dayBefore);
  var previousEvent = scheduleBefore[scheduleBefore.length - 1];

  if (previousEvent) {
    return previousEvent;
  }

  return 2000;
};
export var getEventProgressPercentage = function getEventProgressPercentage(state, homeId, zoneId) {
  var date = new Date();
  var currentMins = dateTimeToMinutesSinceMidnight(date);
  var overrideState = getPlusHoursEndtime(state, homeId, zoneId);
  overrideState = overrideState != null ? moment(overrideState).toDate() : null;
  var nextEventDate = overrideState || date;
  var thisEvent = getCurrentScheduleFullEvent(state, homeId, zoneId, nextEventDate);

  if (thisEvent != null) {
    if (thisEvent.endTime !== thisEvent.startTime) {
      // if running on schedule, show next schedule events
      if (thisEvent.startTime < currentMins) {
        var diff = thisEvent.endTime - thisEvent.startTime;
        var current = currentMins - thisEvent.startTime;
        return current / diff * 100;
      } // if in override show empty ring


      return 0;
    }
  }

  return 0;
};
export var getCurrentScheduleSetpoint = function getCurrentScheduleSetpoint(state, homeId, zoneId, date) {
  var day = getDayOfWeek(date);
  var minsSinceMidnight = dateTimeToMinutesSinceMidnight(date);
  var schedule = getScheduleEvents(state, homeId, zoneId, day);
  var earlier = schedule.filter(function (e) {
    return e.startTime < minsSinceMidnight;
  });
  var result = earlier[earlier.length - 1];

  if (result) {
    return result.setpoint;
  }

  var dayBefore = getPreviousDay(day);
  var scheduleBefore = getScheduleEvents(state, homeId, zoneId, dayBefore);
  var previousEvent = scheduleBefore[scheduleBefore.length - 1];

  if (previousEvent) {
    return previousEvent.setpoint;
  }

  return 2000;
};