/*
 * @format
 */
export var SETTINGS_DISABLED_HEADER = 'Cannot change settings';
export var SETTINGS_DISABLED_BODY = 'Settings cannot be changed while Override, Plus Hours or Holiday mode is enabled. \n \n Please disable Holiday mode or change your heating mode to Auto, Manual or Off in order to change settings.';
export var HOLIDAY_SETTING_DISABLED_HEADER = 'Cannot set holiday';
export var HOLIDAY_SETTING_DISABLED_BODY = 'Holiday mode cannot be set while Override or Plus Hours is active. \n \nPlease change your heating mode to Auto, Manual or Off in order to enter holiday mode.';
export var SCHEDULE_SETTING_DISABLED_HEADER = 'Cannot update schedule';
export var SCHEDULE_SETTING_DISABLED_BODY = 'Schedule cannot be updated while Override or Plus Hours is active. \n \nPlease change your heating mode to Auto, Manual or Off in order to update heating schedule.';
export var IDEAL_CLOUD_LICENCE_NOT_PRESENT = "Please contact us for cost and details on licence activation to allow the full HALO app functionality";
export var IDEAL_CLOUD_LICENCE_NOT_PRESENT_SHORT = "You do not have a licence to access full app functionality.";