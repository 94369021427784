import _defineProperty from "/home/circleci/src/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

/*
 * @format
 */
import * as actions_ from './actions/actions';
import * as controlsActions_ from './actions/controls-actions';
import * as settingsActions_ from './actions/settings-actions';
import * as resumeActions_ from './actions/resume-registration';
import * as selectors_ from './selectors';
import * as controlsSelectors_ from './controls-selectors';
import * as scheduleSelectors_ from './selectors/schedule-selectors';
import * as reducer_ from './reducer';
import * as deviceReducers_ from './device-reducer';
import * as scheduleReducer_ from './reducers/schedule-reducer';
import * as messages_ from './web_socket_messages/messages';
import * as pendingReducers_ from './reducers/pending-reducer';
import * as pendingCustomReducer_ from './reducers/pending-custom-reducer';
import * as completedReducer from './reducers/completed-messages';
import * as siRegistrationReducer from './reducers/si-registration';
import * as resumeRegReducer from './reducers/resume-registration';
import settingsReducer from './reducers/settings-reducer';
import notificationsReducer from './reducers/notifications-reducer';
import modeChangeTracker from './reducers/mode-change-tracker';
import unpairDeviceReducer from './reducers/unpair-device-reducer';
import zoneNamingReducer from './reducers/zone-naming-reducer';
import saga_ from './sagas';
import help from './help/Help';
import policies from './help/Policies';
import jsonApiSetup_ from './api/jsonapi';
import * as deviceGeoStatus from './reducers/device-geo-reducer';
import * as awaitingZoneStatus from './reducers/awaiting-zone-reducer';
export var helpData = help;
export var legalPolicies = policies;
export var actions = _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, actions_), controlsActions_), settingsActions_), resumeActions_);
export var selectors = _objectSpread(_objectSpread(_objectSpread({}, selectors_), controlsSelectors_), scheduleSelectors_);
export var reducers = _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, reducer_), deviceReducers_), scheduleReducer_), deviceGeoStatus), awaitingZoneStatus), pendingReducers_), pendingCustomReducer_), completedReducer), siRegistrationReducer), resumeRegReducer), {}, {
  settingsReducer: settingsReducer,
  notificationsReducer: notificationsReducer,
  modeChangeTracker: modeChangeTracker,
  unpairDeviceReducer: unpairDeviceReducer,
  zoneNamingReducer: zoneNamingReducer
});
export var webSocketMessages = messages_;
export var jsonApiSetup = jsonApiSetup_;
export var rootSaga = saga_;