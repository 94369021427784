/*
 * @format
 */
import * as types from '../action-types';
export var resumeRegistration = function resumeRegistration() {
  return {
    type: types.RESUME_REGISTRATION_START
  };
};
export var resumeRegistrationAbort = function resumeRegistrationAbort() {
  return {
    type: types.RESUME_REGISTRATION_ABORT
  };
};
export var resumeRegistrationWithDeviceDetails = function resumeRegistrationWithDeviceDetails(macAddress, publicKey, connectionValidAfter) {
  return {
    type: types.RESUME_REGISTRATION_RETURN_TO_APP,
    macAddress: macAddress,
    publicKey: publicKey,
    connectionValidAfter: connectionValidAfter
  };
};