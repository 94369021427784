import _slicedToArray from "/home/circleci/src/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import _toConsumableArray from "/home/circleci/src/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";

/*
 * @format
 */
import moment from 'moment';
import { convertToSiTime } from 'darwin_core/utils/helpers';
import { MAX_SETPOINT, MODES } from './utils/consts';
import { isMatchingZoneId } from './utils/helpers';
import { getPendingData } from './reducers/pending-reducer';
import { getPendingSettingsData } from './reducers/settings-reducer';

var pendingErrors = function pendingErrors(state) {
  return [].concat(_toConsumableArray(state.pendingSetpoint.errors), _toConsumableArray(state.pendingMode.errors), _toConsumableArray(state.pendingHolidayMode.errors), _toConsumableArray(state.pendingPublish.errors), _toConsumableArray(state.pendingPrtSettings.errors));
};

export var getFirstPendingError = function getFirstPendingError(state) {
  var p = pendingErrors(state);
  return p.length > 0 ? p[0] : {};
};
export var getPendingErrors = function getPendingErrors(state) {
  var p = pendingErrors(state);
  return p.reduce(function (acc, curr) {
    if (acc.find(function (x) {
      return x.requestId === curr.requestId;
    })) {
      return acc;
    }

    acc.push(curr);
    return acc;
  }, []);
};
export var havePendingErrors = function havePendingErrors(state) {
  return pendingErrors(state).length > 0;
};
export var getZone = function getZone(state, homeId, zoneId) {
  var home = state.homeList.homes.find(function (x) {
    return x.id === homeId;
  });
  var zone = home.zones.find(function (x) {
    return isMatchingZoneId(x.zoneId, zoneId);
  });
  return zone;
};
export var getUpperLimitLock = function getUpperLimitLock(state, homeId, zoneId) {
  // Maximum setpoint allowed as configured by the landlord (DV-2448)
  var zone = getZone(state, homeId, zoneId);

  if (zone && zone.upperLimitLock) {
    return zone.upperLimitLock;
  }

  return null;
};
export var getLowerLimitLock = function getLowerLimitLock(state, homeId, zoneId) {
  // Minimum setpoint allowed as configured by the landlord (DV-2448)
  var zone = getZone(state, homeId, zoneId);

  if (zone && zone.lowerLimitLock) {
    return zone.lowerLimitLock;
  }

  return null;
};
export var getFrostProtectionSetpoint = function getFrostProtectionSetpoint(state, homeId, zoneId) {
  var pendingState = getPendingSettingsData(state.pendingPrtSettings, zoneId, 'frostProtectionSetpoint');

  if (pendingState && pendingState.prtSetting === 'frostProtectionSetpoint') {
    // the pending value for frost protection is stored as a string as that
    // is what the SI expects in setPrtSettings
    return parseInt(pendingState.value, 10);
  } // if you have one, you get it back - if not you get default 500


  var zone = getZone(state, homeId, zoneId);

  if (zone && zone.frostProtectionSetpoint) {
    return zone.frostProtectionSetpoint;
  }

  return 500;
};
export var isInHolidayMode = function isInHolidayMode(state, todayDate, homeId, zoneId) {
  var zone = getZone(state, homeId, zoneId);
  var pendingHoliday = getPendingData(state.pendingHolidayMode, homeId, zoneId);

  if (pendingHoliday) {
    return pendingHoliday.holidayMode === 3;
  }

  var holidayMode = zone.holidayMode;
  return holidayMode === 3;
};
export var isReallyInHolidayMode = function isReallyInHolidayMode(state, homeId, zoneId) {
  var zone = getZone(state, homeId, zoneId);
  var holidayMode = zone.holidayMode;
  return holidayMode === 3;
};
export var controlsDisabled = function controlsDisabled(state, homeId, zoneId) {
  var pendingHoliday = getPendingData(state.pendingHolidayMode, homeId, zoneId);
  var holidayMode = isInHolidayMode(state, null, homeId, zoneId); // if a holidayMode is still active keep controls disabled

  if (holidayMode) {
    return true;
  } // if the pending holiday data has not got the holidayMode key it is a cancelHoliday message


  if (pendingHoliday && pendingHoliday.holidayMode === undefined) {
    // if the pending holiday data has the requestId and it equals "done" return false e.g. controlsDisabled = false
    // if the pending holiday data has the requestId and it doesn't equal "done" return true e.g. controlsDisabled = true
    return !(pendingHoliday.requestId === 'done');
  } // if both holidayMode and pendingHoliday are falsy return false e.g. controlsDisabled = false


  return false;
};
export var getMode = function getMode(state, homeId, zoneId) {
  if (typeof state !== 'undefined') {
    var pendingState = getPendingData(state.pendingMode, homeId, zoneId);

    if (pendingState && pendingState.mode) {
      return pendingState.mode;
    }

    var zone = getZone(state, homeId, zoneId);
    return zone ? zone.mode : null;
  }

  return null;
};
export var getSetpoint = function getSetpoint(state, homeId, zoneId) {
  var pendingState = getPendingData(state.pendingSetpoint, homeId, zoneId);
  var nextSetpointValue = null;
  var zone = getZone(state, homeId, zoneId);

  if (pendingState && typeof pendingState.setpoint === 'number') {
    // if pending
    nextSetpointValue = pendingState.setpoint;
  }

  if (nextSetpointValue == null) {
    // if no pending change
    nextSetpointValue = zone.setpoint;
  }

  var frostProtectSetpoint = getFrostProtectionSetpoint(state, homeId, zoneId);
  var mode = getMode(state, homeId, zoneId);

  if (isInHolidayMode(state, null, homeId, zoneId) || mode === MODES.Off) {
    return frostProtectSetpoint;
  }

  if (nextSetpointValue != null) {
    if (nextSetpointValue <= frostProtectSetpoint) {
      return frostProtectSetpoint; // handle low out of range value
    }

    if (nextSetpointValue > MAX_SETPOINT) {
      return MAX_SETPOINT; // handle high out of range value
    }

    return nextSetpointValue; // otherwise return fine
  }

  return null;
};
export var getPreviousTemperatureMode = function getPreviousTemperatureMode(state, homeId, zoneId) {
  var zone = getZone(state, homeId, zoneId);

  if (zone) {
    return zone.previousTemperatureMode;
  }

  return null;
}; // not used for geolocation

export var getPreviousMode = function getPreviousMode(state, homeId, zoneId) {
  var pendingState = getPendingData(state.pendingMode, homeId, zoneId);

  if (pendingState && pendingState.previousMode) {
    return pendingState.previousMode;
  }

  var zone = getZone(state, homeId, zoneId);

  if (zone) {
    return zone.previousMode;
  }

  return null;
};
export var getPreviousSetPoint = function getPreviousSetPoint(state, homeId, zoneId) {
  var zone = getZone(state, homeId, zoneId);

  if (zone) {
    return zone.previousSetpoint;
  }

  return null;
}; // ignores the pending state - used for debug users to
// see what we are being sent by the si

export var getLastReportedZoneData = function getLastReportedZoneData(state, homeId, zoneId) {
  var zone = getZone(state, homeId, zoneId);

  if (zone) {
    return {
      setpoint: zone.setpoint,
      mode: zone.mode,
      holidayMode: zone.holidayMode
    };
  }

  return {
    setpoint: 0,
    mode: 'unknown'
  };
};
export var getPlusHoursEndtime = function getPlusHoursEndtime(state, homeId, zoneId) {
  var mode = getMode(state, homeId, zoneId);

  if (mode === MODES.OverridePlusHours) {
    // stop us getting into this state when we're not overriding
    var pendingState = getPendingData(state.pendingMode, homeId, zoneId);
    var siNowTime = convertToSiTime(Date.now());

    if (pendingState && pendingState.overrideEndtime && convertToSiTime(pendingState.overrideEndtime).isAfter(siNowTime)) {
      return convertToSiTime(pendingState.overrideEndtime);
    }

    var zone = getZone(state, homeId, zoneId);

    if (zone && convertToSiTime(zone.overrideEndtime).isAfter(siNowTime)) {
      return convertToSiTime(zone.overrideEndtime);
    }

    return null;
  }

  return null;
};
export var getPendingDebugData = function getPendingDebugData(state, homeId, zoneId) {
  var pendingModeData = getPendingData(state.pendingMode, homeId, zoneId);
  var pendingSetpointData = getPendingData(state.pendingSetpoint, homeId, zoneId);
  return [pendingModeData, pendingSetpointData];
};
export var getHolidayDuration = function getHolidayDuration(state, homeId, zoneId) {
  var zone = getZone(state, homeId, zoneId);
  var pendingHoliday = getPendingData(state.pendingHolidayMode, homeId, zoneId);
  var holidayEndTime = pendingHoliday ? pendingHoliday.holidayEndTime : zone.holidayModeEnd;
  return moment(holidayEndTime).diff(moment(), 'days');
};
export var isFrostProtectionActive = function isFrostProtectionActive(state, homeId, zoneId) {
  var mode = getMode(state, homeId, zoneId);
  var setpoint = getSetpoint(state, homeId, zoneId);
  var zone = getZone(state, homeId, zoneId);

  if (!zone) {
    return true;
  }

  return mode === MODES.Off || setpoint <= getFrostProtectionSetpoint(state, homeId, zoneId);
};
export var isAutoOff = function isAutoOff(state, homeId, zoneId, inHolidayMode) {
  if (inHolidayMode) {
    return false;
  }

  var mode = getMode(state, homeId, zoneId);
  var setpoint = getSetpoint(state, homeId, zoneId);
  var zone = getZone(state, homeId, zoneId);

  if (!zone) {
    return false;
  }

  return mode === MODES.Auto && setpoint <= getFrostProtectionSetpoint(state, homeId, zoneId);
};
export var getDhwMode = function getDhwMode(state, homeId, zoneId, currentEvent) {
  var mode = getMode(state, homeId, zoneId);
  var isAutoOffDhw = !currentEvent.on;

  if (isInHolidayMode(state, null, homeId, zoneId) || mode === MODES.Off) {
    return MODES.Off;
  }

  if (mode === MODES.Auto) {
    if (isAutoOffDhw) {
      return MODES.Off;
    }

    return MODES.On;
  }

  if (mode === MODES.OverrideNext || mode === MODES.OverridePlusHours) {
    return MODES.On;
  }

  return mode;
};
export var isMaxSetpoint = function isMaxSetpoint(setpoint) {
  return setpoint === MAX_SETPOINT;
};

var getLastCompletedMessage = function getLastCompletedMessage(state, homeId, _ref) {
  var _ref2 = _slicedToArray(_ref, 2),
      zoneId = _ref2[0],
      endpointId = _ref2[1];

  var home = state.completedMessages[homeId];

  if (!home) {
    return null;
  }

  var zone = home[zoneId];

  if (!zone) {
    return null;
  }

  var ep = zone[endpointId];

  if (!ep) {
    return null;
  }

  return ep.last;
};

export var getCompletedStatusText = function getCompletedStatusText(state, homeId, zoneId) {
  var message = getLastCompletedMessage(state, homeId, zoneId);

  if (message && message.command) {
    switch (message.command) {
      case 'cancelOverride':
        return zoneId[1] === 6 ? 'Boost Cancelled' : 'Override Cancelled';

      default:
        return zoneId[1] === 6 ? 'Hot Water set to' : 'Heating set to';
    }
  }

  return zoneId[1] === 6 ? 'Hot Water set to' : 'Heating set to';
};
export var isCloudSupported = function isCloudSupported(state, homeId) {
  if (!homeId) {
    return false;
  }

  var home = state.homeList.homes.find(function (x) {
    return x.id === homeId;
  });

  if (!home) {
    return false;
  }

  if (home.cloudSupported === false) {
    return false;
  }

  return true;
};
export var canSettingsBeSent = function canSettingsBeSent(state, homeId, zoneId) {
  var cloudSupported = isCloudSupported(state, homeId);

  if (!cloudSupported) {
    // block options if no cloud license
    return false;
  }

  if (controlsDisabled(state, homeId, zoneId)) {
    return false;
  }

  var zoneMode = getZone(state, homeId, zoneId);

  if (zoneMode === MODES.OverrideNext || zoneMode === MODES.OverridePlusHours) {
    return false;
  }

  var mode = getMode(state, homeId, zoneId);

  switch (mode) {
    case MODES.On:
    case MODES.Off:
    case MODES.Manual:
    case MODES.Auto:
      return true;

    default:
      return false;
  }
};
export var waitingForMessage = function waitingForMessage(state, homeId) {
  var home = state.modeChangeTracker[homeId];

  if (!home) {
    return false;
  }

  return home;
};
export var getlatestSchedule = function getlatestSchedule(state, homeId, zoneId) {
  var zone = getZone(state, homeId, zoneId);

  if (zone) {
    return {
      schedule: zone.schedule
    };
  }

  return {
    schedule: []
  };
};