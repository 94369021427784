import React from 'react';
import {connect} from 'react-redux';

import Home from './screens/Home';
import WebSocketHandler from './components/WebSocketHandler';
import Authentication from './screens/Authentication';
import {actions} from 'darwin_core';

const main = (props) => {

  const { webSocketOpened, webSocketMessageReceived, webSocketClosed } = props;
  // const webSocketOpened = () => console.log("Open!");
  // const webSocketClosed = () => console.log("Closed!");
  // const receivedWebSocketData = (data) => console.log("Received", data);

  return (
    <Authentication>
      <WebSocketHandler
        onOpen={webSocketOpened}
        onMessage={e => webSocketMessageReceived(e)}
        onClose={e => webSocketClosed(e.code, e.reason)}
      >
        {(sendWsMessage, webSocketInfo) => {
          return (
              <Home
                  {...props}
                  sendWsMessage={sendWsMessage}
                  webSocketStatus={webSocketInfo.webSocketStatus}
                  showDisconnectedModal={webSocketInfo.showDisconnectedModal}
              />
          );
        }}
      </WebSocketHandler>

    </Authentication>
)};

const mapStateToProps = state => ({

});

export default connect(mapStateToProps, actions)(main);
