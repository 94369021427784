import _defineProperty from "/home/circleci/src/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

/*
 * @format
 */
import * as actionTypes from '../action-types';
var STATES = {
  NOT_STARTED: 'notStarted',
  STARTED: 'started',
  PENDING: 'pending',
  TIMEOUT: 'timeout',
  COMPLETE: 'complete'
};
export var initialState = {
  state: STATES.NOT_STARTED,
  error: ''
};
export var pendingCustomReducer = function pendingCustomReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case actionTypes.CUSTOM_PROPERTIES_UPDATE_STARTED:
      return _objectSpread(_objectSpread({}, state), {}, {
        state: STATES.PENDING
      });

    case actionTypes.CUSTOM_PROPERTIES_UPDATE_TIMEOUT:
      if (state.state === STATES.PENDING) {
        return _objectSpread(_objectSpread({}, state), {}, {
          state: STATES.TIMEOUT
        });
      }

      return state;

    case actionTypes.CUSTOM_PROPERTIES_UPDATE_COMPLETED:
      return initialState;

    case actionTypes.CUSTOM_PROPERTIES_UPDATE_RESET:
      return initialState;

    default:
      return state;
  }
};