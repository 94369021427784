import _defineProperty from "/home/circleci/src/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

/*
 * @format
 *
 * Reducer to capture the last completed commands from the API/device
 *
 */
import * as actionTypes from '../action-types';
/*
const example = {

  'home123': {
    'zone123': {
      last: {
        requestId: 'request1234',
        command: 'setPrtHoliday',
      }
    }
  },

  requests: {
    'request1234': {
      homeId: 'home123'
      zoneId: 'zone123'
    }
  }
};
*/

export var initalState = {
  requests: {}
};
export var completedMessages = function completedMessages() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initalState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case actionTypes.COMMAND_SENT:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          requests: _objectSpread(_objectSpread({}, state.requests), {}, _defineProperty({}, action.message.data.requestId, {
            homeId: action.message.data.siId,
            zoneId: action.message.data.prtId,
            endpointId: action.message.data.endpointId
          }))
        });
      }

    case actionTypes.COMMAND_COMPLETED:
      {
        var _objectSpread4;

        var request = state.requests[action.message.requestId];

        if (!request) {
          return state;
        }

        return _objectSpread(_objectSpread({}, state), {}, (_objectSpread4 = {}, _defineProperty(_objectSpread4, request.homeId, _defineProperty({}, request.zoneId, _defineProperty({}, request.endpointId, {
          last: action.message
        }))), _defineProperty(_objectSpread4, "requests", _objectSpread(_objectSpread({}, state.requests), {}, _defineProperty({}, action.message.requestId, undefined))), _objectSpread4));
      }

    case actionTypes.CLEAR_LAST_COMPLETED_COMMAND:
      {
        var _action$message$data = action.message.data,
            siId = _action$message$data.siId,
            prtId = _action$message$data.prtId,
            endpointId = _action$message$data.endpointId;
        return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, siId, _defineProperty({}, prtId, _defineProperty({}, endpointId, {
          last: undefined
        }))));
      }

    case actionTypes.PENDING_CHANGE_TIMED_OUT:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          requests: _objectSpread(_objectSpread({}, state.requests), {}, _defineProperty({}, action.requestId, undefined))
        });
      }

    default:
      {
        return state;
      }
  }
};