import React from 'react';
import Button from './common/Button';

export default class SetOverride extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      setpoint: props.setpoint || 0,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { setpoint } = this.state;

    if (prevState.setpoint !== setpoint) {
      this.setState({ setpoint });
    }
  }

  render() {
    const { setpoint } = this.state;
    const { setOverrideSetpoint, selectedHome } = this.props;

    return (
      <div>
        <Button onPress={() => {
          const desiredSetpoint = setpoint - 1;
          setOverrideSetpoint(selectedHome, desiredSetpoint);
          this.setState({ setpoint: desiredSetpoint });
        }}
        >
         -
        </Button>
        <p>Desired: {setpoint}</p>
        <Button onPress={() => {
          const desiredSetpoint = setpoint + 1;
          setOverrideSetpoint(selectedHome, desiredSetpoint);
          this.setState({ setpoint: desiredSetpoint });
        }}
        >
        +
        </Button>
      </div>
    );
  }
}
