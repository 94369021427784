/*
 * @format
 *
 * Helpers for initalising event objects
 */
import { DAYS_OF_WEEK } from '../utils/consts';
export var event = function event(day, startTime, setpoint) {
  return {
    day: day,
    startTime: startTime,
    setpoint: setpoint
  };
};
export var eventUpdate = function eventUpdate(day, startTime, endTime, setpoint) {
  return {
    day: day,
    startTime: startTime,
    endTime: endTime,
    setpoint: setpoint
  };
};
export var daySortIndex = function daySortIndex(day) {
  switch (day) {
    case DAYS_OF_WEEK.MONDAY:
      return 0;

    case DAYS_OF_WEEK.TUESDAY:
      return 1;

    case DAYS_OF_WEEK.WEDNESDAY:
      return 2;

    case DAYS_OF_WEEK.THURSDAY:
      return 3;

    case DAYS_OF_WEEK.FRIDAY:
      return 4;

    case DAYS_OF_WEEK.SATURDAY:
      return 5;

    case DAYS_OF_WEEK.SUNDAY:
      return 6;

    default:
      throw Error('Unknown day constant used');
  }
};
export var nextDay = function nextDay(day) {
  switch (day) {
    case DAYS_OF_WEEK.MONDAY:
      return DAYS_OF_WEEK.TUESDAY;

    case DAYS_OF_WEEK.TUESDAY:
      return DAYS_OF_WEEK.WEDNESDAY;

    case DAYS_OF_WEEK.WEDNESDAY:
      return DAYS_OF_WEEK.THURSDAY;

    case DAYS_OF_WEEK.THURSDAY:
      return DAYS_OF_WEEK.FRIDAY;

    case DAYS_OF_WEEK.FRIDAY:
      return DAYS_OF_WEEK.SATURDAY;

    case DAYS_OF_WEEK.SATURDAY:
      return DAYS_OF_WEEK.SUNDAY;

    case DAYS_OF_WEEK.SUNDAY:
      return DAYS_OF_WEEK.MONDAY;

    default:
      throw Error('Unknown day constant used');
  }
};
export var shortenDay = function shortenDay(day) {
  var locationOfSAfterFirstChar = day.indexOf('s', 1);

  if (locationOfSAfterFirstChar !== -1) {
    if (locationOfSAfterFirstChar <= 4) {
      return day.substring(0, locationOfSAfterFirstChar + 1);
    }
  }

  return day.substring(0, 3);
};