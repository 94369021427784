import React from 'react';
import Button from './common/Button';

export default function HomeSelector({ nextHome, previousHome, selectedHome }) {
  return (
    <div>
      <Button onPress={() => previousHome()}>
        Previous home
      </Button>
      
      <p>{JSON.stringify(selectedHome)}</p>

      <Button onPress={() => nextHome()}>
        Next home
      </Button>
    </div>
  );
}
