import React from 'react';

export default class WebSocketDisconnected extends React.Component {

  render() {
    return (
      <div>
        <p>Disconnected, attempting to reconnect...</p>
      </div>
    );
  }
}