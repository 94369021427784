import _toConsumableArray from "/home/circleci/src/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _defineProperty from "/home/circleci/src/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _toArray from "/home/circleci/src/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

/*
 * @format
 *
 * Functions to manage schedules
 */
import { daySortIndex, nextDay } from './event';
import { dateTimeToMinutesSinceMidnight, dateToLongDayString, getMinutesSinceSunday, getScheduleEventAction, convertToSiTime, formatTime } from '../utils/helpers';
import { MINUTES_SINCE_SUNDAY } from '../utils/consts';
export var sortSchedule = function sortSchedule(a, b) {
  var days = daySortIndex(a.day) - daySortIndex(b.day);

  if (days !== 0) {
    return days;
  }

  return a.startTime - b.startTime;
};

var sortByStartTime = function sortByStartTime(a, b) {
  return a.startTime - b.startTime;
};

export var weekifySchedule = function weekifySchedule(_ref) {
  var _ref3;

  var _ref2 = _toArray(_ref),
      schedule = _ref2.slice(0);

  var nested = schedule.map(function (s) {
    return s.events.map(function (p) {
      return _objectSpread({
        startTime: MINUTES_SINCE_SUNDAY[s.day] + p.startTime
      }, getScheduleEventAction(p));
    });
  });

  var merged = (_ref3 = []).concat.apply(_ref3, _toConsumableArray(nested));

  return merged.sort(sortByStartTime);
};

var parseEvent = function parseEvent(event, timeDisplayFormat) {
  if (!event) {
    return '';
  }

  var minutesSinceMidnight = event.startTime % 1440;
  return _objectSpread({
    minutesSinceSunday: event.startTime,
    minutesSinceMidnight: minutesSinceMidnight,
    displayTime: formatTime(minutesSinceMidnight, timeDisplayFormat),
    day: Object.keys(MINUTES_SINCE_SUNDAY)[Math.floor(event.startTime / 1440)]
  }, getScheduleEventAction(event));
};

export var getEventAt = function getEventAt(schedule, minutesSinceSunday) {
  if (schedule.length === 0) {
    return undefined;
  }

  var reversedArray = _toConsumableArray(schedule).reverse();

  var event = reversedArray.find(function (x) {
    return x.startTime <= minutesSinceSunday;
  }) || schedule[schedule.length - 1];
  return parseEvent(event);
};
export var getCurrentEvent = function getCurrentEvent(schedule) {
  var minutesSinceSunday = getMinutesSinceSunday(convertToSiTime(Date.now()));
  return getEventAt(schedule, minutesSinceSunday);
};
export var getNextEvent = function getNextEvent(schedule, plusHoursEndtime, timeDisplayFormat) {
  if (plusHoursEndtime) {
    var overrideMinutesSinceMidnight = dateTimeToMinutesSinceMidnight(plusHoursEndtime);
    var currentDay = dateToLongDayString(plusHoursEndtime);
    var overrideMinutes = MINUTES_SINCE_SUNDAY[currentDay] + overrideMinutesSinceMidnight;
    var overrideEvent = getEventAt(schedule, overrideMinutes);
    return parseEvent({
      startTime: overrideMinutes,
      setpoint: overrideEvent.setpoint,
      on: overrideEvent.on
    }, timeDisplayFormat);
  }

  if (schedule.length === 0) {
    return undefined;
  }

  var minutesSinceSunday = getMinutesSinceSunday(convertToSiTime(Date.now())); // if no event is found it is the last event of the week
  // and should rollover to the first

  var nextEvent = schedule.find(function (x) {
    return x.startTime > minutesSinceSunday;
  }) || schedule[0];
  return parseEvent(nextEvent, timeDisplayFormat);
};
export var getEventProgress = function getEventProgress(schedule) {
  var minutesSinceSunday = getMinutesSinceSunday(convertToSiTime(Date.now()));
  var currentEvent = getEventAt(schedule, minutesSinceSunday); // don't pass override endtime as we don't want it included in the schedule progress

  var nextEvent = getNextEvent(schedule, undefined);

  if (!currentEvent || !nextEvent) {
    return 0;
  }

  var diff = Math.abs(nextEvent.minutesSinceSunday - currentEvent.minutesSinceSunday);
  var current = minutesSinceSunday - currentEvent.minutesSinceSunday;
  return current / diff * 100;
};
export var getEventsForDay = function getEventsForDay(schedule, day) {
  if (!Array.isArray(schedule)) {
    throw Error('Schedule was not an array');
  }

  return schedule.sort(sortSchedule).reduce(function (acc, curr, idx, src) {
    var endTime;

    if (idx + 1 > src.length - 1) {
      endTime = src[0].startTime;
    } else {
      endTime = src[idx + 1].startTime;
    }

    acc.push(_objectSpread(_objectSpread({}, curr), {}, {
      endTime: endTime
    }));
    return acc;
  }, []).filter(function (x) {
    return x.day === day;
  });
};
export var flattenSchedule = function flattenSchedule(_ref4) {
  var _ref6;

  var _ref5 = _toArray(_ref4),
      schedule = _ref5.slice(0);

  if (!Array.isArray(schedule)) {
    throw Error('Schedule was not an array');
  }

  if (!(schedule.length === 0)) {
    if (!Array.isArray(schedule[0].events)) {
      throw Error("It looks like you've flattened this object already. If this is wrong, check the object you're passing in for an 'events' array");
    }
  }

  var nested = schedule.map(function (s) {
    return s.events.map(function (event) {
      return _objectSpread({
        day: s.day,
        startTime: event.startTime
      }, getScheduleEventAction(event));
    });
  });

  var merged = (_ref6 = []).concat.apply(_ref6, _toConsumableArray(nested));

  return merged.sort(sortSchedule);
};
export var unflattenSchedule = function unflattenSchedule(_ref7) {
  var _ref8 = _toArray(_ref7),
      schedule = _ref8.slice(0);

  if (!Array.isArray(schedule)) {
    throw Error('Schedule was not an array');
  }

  if (Array.isArray(schedule[0].events)) {
    throw Error("It looks like you've unflattened this object already. If this is wrong, check the object you're passing in for an 'events' array");
  }

  return schedule.reduce(function (acc, curr) {
    var day = acc.find(function (d) {
      return d.day === curr.day;
    });

    if (!day) {
      day = {
        day: curr.day,
        events: []
      };
      acc.push(day);
    }

    day.events.push(_objectSpread({
      startTime: curr.startTime
    }, getScheduleEventAction(curr)));
    return acc;
  }, []);
};
export var findEventInDay = function findEventInDay(schedule, day, startTime) {
  return schedule.findIndex(function (x) {
    return x.day === day && x.startTime === startTime;
  });
};
export var findNextEventIndexInDay = function findNextEventIndexInDay(schedule, day, startTime) {
  return schedule.findIndex(function (x) {
    return x.day === day && x.startTime > startTime;
  });
};
export var getUsedStartTimes = function getUsedStartTimes(day, scheduleEvents) {
  return scheduleEvents.filter(function (x) {
    return x.day === day;
  }).map(function (x) {
    return x.startTime;
  });
};
export var defaultStartTime = function defaultStartTime(usedStartTimes) {
  var twelveHoursInMinutes = 720;

  if (!usedStartTimes) {
    return twelveHoursInMinutes;
  }

  var startTime = twelveHoursInMinutes;

  for (var i = 0; i < usedStartTimes.length; i + 1) {
    if (!usedStartTimes.includes(startTime)) {
      return startTime;
    }

    startTime += 15;
  }

  return startTime;
};
export var defaultEndTime = function defaultEndTime(schedule, day, startTime) {
  var sortedSchedule = _toConsumableArray(schedule).sort(sortSchedule);

  var nextEventIndex = findNextEventIndexInDay(sortedSchedule, day, startTime);
  var nextEventInDay = schedule[nextEventIndex];

  if (!nextEventInDay) {
    var nextDayInSchedule = nextDay(day);
    var nextDaysEvents = sortedSchedule.filter(function (event) {
      return event.day === nextDayInSchedule;
    });
    return nextDaysEvents[0].startTime;
  }

  return nextEventInDay.startTime;
};
export var finalEndTime = function finalEndTime(schedule, day) {
  if (!schedule || !day) {
    throw new Error('No schedule or day specified');
  }

  var sortedSchedule = _toConsumableArray(schedule).sort(sortSchedule);

  var nextDayInSchedule = nextDay(day);
  var nextDaysEvents = sortedSchedule.filter(function (event) {
    return event.day === nextDayInSchedule;
  });
  return nextDaysEvents[0].startTime;
};
export var endTimeLimit = function endTimeLimit(schedule, day, startTime) {
  if (!schedule || !day || !startTime) {
    throw new Error('No schedule, day or startTime specified');
  }

  var sortedSchedule = _toConsumableArray(schedule).sort(sortSchedule);

  var eventsLaterThanStartTime = sortedSchedule.filter(function (event) {
    return event.day === day && event.startTime > startTime;
  });

  if (!eventsLaterThanStartTime[1]) {
    return finalEndTime(sortedSchedule, day);
  }

  return eventsLaterThanStartTime[1].startTime;
};
export var addEvent = function addEvent(schedule, event) {
  if (!Array.isArray(schedule)) {
    throw Error('Schedule was not an array');
  }

  var safeEvent = _objectSpread({
    day: event.day,
    startTime: event.startTime
  }, getScheduleEventAction(event));

  if (schedule.length === 0) {
    return [safeEvent];
  } // copy the array so we don't mutate the old schedule


  var mutableSched = _toConsumableArray(schedule).sort(sortSchedule);

  if (event.endTime) {
    // find next event after start time
    var nextEventIndex = findNextEventIndexInDay(mutableSched, event.day, event.startTime);

    if (nextEventIndex < 0) {
      // if the nextEventIndex is less than 0 then the event
      // being added is the new last event of the day
      var specifiedDayEndTimeIndex = findEventInDay(mutableSched, nextDay(event.day), finalEndTime(mutableSched, event.day)); // adding the new event but not updating any other events

      mutableSched.splice(specifiedDayEndTimeIndex, 0, safeEvent);
      return mutableSched.sort(sortSchedule);
    }

    var nextEvent = mutableSched[nextEventIndex]; // remove the next event, and re-add with new start time

    mutableSched.splice(nextEventIndex, 1, safeEvent, _objectSpread({
      day: nextEvent.day,
      startTime: event.endTime
    }, getScheduleEventAction(nextEvent))); // add affected events

    return mutableSched.sort(sortSchedule);
  } // add affected events


  return schedule.concat([safeEvent]).sort(sortSchedule);
};
export var deleteEvent = function deleteEvent(schedule, event) {
  if (!Array.isArray(schedule)) {
    throw Error('Schedule was not an array');
  }

  var mutableSched = schedule.filter(function (s) {
    return !(s.day === event.day && s.startTime === event.startTime);
  });
  return mutableSched.sort(sortSchedule);
};
export var editEvent = function editEvent(schedule, oldEvent, newEvent) {
  var mutableSched = deleteEvent(schedule, oldEvent);
  return addEvent(mutableSched, newEvent);
};