import React from 'react';
import ModalR from 'react-modal';

// Modal accessibility fix
if (process.env.NODE_ENV !== 'test') ModalR.setAppElement('#root');

const Modal = ({ visible, children }) => (
  <ModalR isOpen={visible}>
    { children }
  </ModalR>
);

export default Modal;
