import _toConsumableArray from "/home/circleci/src/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _defineProperty from "/home/circleci/src/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

/*
 * @format
 */
import uuid from 'uuid/v4';
import * as actionTypes from '../action-types'; // [
//   {
//     requestId: asd876a-aksjdbakj-a271wdbjkas,
//     homeId: '25062019000000',
//     title: 'prt-123 has lost connection',
//     type: 'deviceOffline',
//     body: 'Check the System Status page for tips on how to get your device back online',
//     raised: 1571223842127
//   }
// ]

var initialState = {
  notifications: []
};
export default (function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case actionTypes.LOGOUT_COMPLETED:
      return initialState;

    case actionTypes.NOTIFICATION_TOKEN_UPDATED:
      return _objectSpread(_objectSpread({}, state), {}, {
        deviceToken: action.deviceToken
      });

    case actionTypes.NOTIFICATION_RECEIVED:
      return _objectSpread(_objectSpread({}, state), {}, {
        notifications: [].concat(_toConsumableArray(state.notifications), [{
          requestId: uuid(),
          homeId: action.data.homeId,
          title: action.data.title,
          body: action.data.body,
          type: action.data.type,
          raised: Date.now()
        }])
      });

    case actionTypes.DISMISS_ERROR:
      return _objectSpread(_objectSpread({}, state), {}, {
        notifications: _toConsumableArray(state.notifications.filter(function (x) {
          return x.requestId !== action.requestId;
        }))
      });

    default:
      return state;
  }
});