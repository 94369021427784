import _toConsumableArray from "/home/circleci/src/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _defineProperty from "/home/circleci/src/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _objectWithoutProperties from "/home/circleci/src/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

/*
 * @format
 *
 * This is a generator for a reducer that follows the pending state pattern we've prototyped.
 * Reported state is stored separately.
 * Users desired changes are stored here when the user makes a change.
 * The selector picks the pending state, if that doesn't exist it uses the real one
 *
 * When we receive an rejection or timeout
 * - We add an error to the array (and the ui will pop a modal)
 * - The pending value is removed
 *
 * When we receive a completion
 * - update the request id to 'done' so other updates won't match
 * - but it can be read by the selectors
 *
 * When we get a Live message
 * - Clear out requests that are 'done'.
 *
 */
import * as actionTypes from '../action-types';
/*
const examle = {
  requests: [
    {
      zoneDeviceId: 'prt-123',
      endpointId: 0,
      requestId: 'abcd-efgh-1234-5678',
      request: {
        setpoint: 2000,
      },
    },
  ],
  errors: [
    {
      zoneDeviceId: 'prt-123',
      endpointId: 0,
      requestId: 'abcd-efgh-1234-5678',
      message: 'There was an error',
    },
  ],
};
*/

export var initalState = {
  requests: [],
  errors: []
};

var matchingHomeId = function matchingHomeId(request, homeId) {
  return request.homeId === homeId;
};

var matchingZoneId = function matchingZoneId(request, zoneDeviceId, endpointId) {
  return request.zoneDeviceId === zoneDeviceId && request.endpointId === endpointId;
};

export var pendingGenerator = function pendingGenerator(startType, rejectedType, completedType) {
  var errorLabel = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'Command';
  var clearType = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : actionTypes.WEBSOCKET_LIVE_RECEIVED;
  return function () {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initalState;
    var action = arguments.length > 1 ? arguments[1] : undefined;

    // split the type value off
    var type = action.type,
        actionData = _objectWithoutProperties(action, ["type"]);

    switch (type) {
      case startType:
        {
          // split manditory and data values
          var homeId = actionData.homeId,
              zoneId = actionData.zoneId,
              requestId = actionData.requestId,
              data = _objectWithoutProperties(actionData, ["homeId", "zoneId", "requestId"]);

          if (!zoneId) {
            return _objectSpread(_objectSpread({}, state), {}, {
              requests: [].concat(_toConsumableArray(state.requests.filter(function (request) {
                return !matchingHomeId(request, homeId);
              })), [// add a new one
              _objectSpread({
                homeId: homeId,
                requestId: requestId
              }, data)]),
              errors: []
            });
          }

          return _objectSpread(_objectSpread({}, state), {}, {
            requests: [].concat(_toConsumableArray(state.requests.filter(function (request) {
              return !matchingZoneId(request, zoneId[0], zoneId[1]);
            })), [// add a new one
            _objectSpread({
              homeId: homeId,
              zoneDeviceId: zoneId[0],
              endpointId: zoneId[1],
              requestId: requestId
            }, data)]),
            errors: []
          });
        }

      case completedType:
        {
          var _requestId = actionData.requestId || actionData.data.requestId; // remove entry if it exists


          var entry = state.requests.find(function (r) {
            return r.requestId === _requestId;
          });

          if (entry) {
            return _objectSpread(_objectSpread({}, state), {}, {
              requests: [].concat(_toConsumableArray(state.requests.filter(function (request) {
                return request.requestId !== _requestId;
              })), [_objectSpread(_objectSpread({}, entry), {}, {
                requestId: 'done'
              })])
            });
          }

          break;
        }

      case rejectedType:
      case actionTypes.PENDING_CHANGE_TIMED_OUT:
        {
          // find request,
          // remove it,
          // if it was removed, add an error
          var _requestId2 = actionData.requestId || actionData.data.requestId;

          var _entry = state.requests.find(function (request) {
            return request.requestId === _requestId2;
          });

          if (_entry) {
            var message = type === rejectedType ? "".concat(errorLabel, " rejected") : "".concat(errorLabel, " timed out");
            return _objectSpread(_objectSpread({}, state), {}, {
              requests: _toConsumableArray(state.requests.filter(function (request) {
                return request.requestId !== _requestId2;
              })),
              errors: [].concat(_toConsumableArray(state.errors), [{
                requestId: _requestId2,
                type: type === rejectedType ? 'commandRejected' : 'commandTimeout',
                raised: Date.now(),
                homeId: _entry.homeId,
                zoneDeviceId: _entry.zoneDeviceId,
                endpointId: _entry.endpointId,
                message: message
              }])
            });
          }

          break;
        }

      case clearType:
        {
          // if the action specifies a home/zone to clear use that
          if (actionData.homeId) {
            return _objectSpread(_objectSpread({}, state), {}, {
              requests: _toConsumableArray(state.requests.filter(function (request) {
                return !(request.homeId === actionData.homeId);
              }))
            });
          } // otherwise remove all done states


          return _objectSpread(_objectSpread({}, state), {}, {
            requests: _toConsumableArray(state.requests.filter(function (request) {
              return request.requestId !== 'done';
            }))
          });
        }

      case actionTypes.DISMISS_ERROR:
        {
          var _requestId3 = actionData.requestId;
          return _objectSpread(_objectSpread({}, state), {}, {
            errors: state.errors.filter(function (request) {
              return request.requestId !== _requestId3;
            })
          });
        }

      default:
        break;
    }

    return state;
  };
};
export var getPendingData = function getPendingData(state, homeId, zoneId) {
  if (!state || !state.requests) {
    return [];
  }

  return state.requests.find(function (r) {
    return r.homeId === homeId && r.zoneDeviceId === zoneId[0] && r.endpointId === zoneId[1];
  });
};
export var getPendingError = function getPendingError(state) {
  return state.errors[0];
};
export var pendingSetpoint = pendingGenerator(actionTypes.SET_SETPOINT_STARTED, actionTypes.WEBSOCKET_SET_SETPOINT_REJECTED, actionTypes.WEBSOCKET_SET_SETPOINT_RECEIVED, 'Setpoint', actionTypes.CLEAR_PENDING_STATE);
export var pendingMode = pendingGenerator(actionTypes.SET_MODE_STARTED, actionTypes.WEBSOCKET_SET_PRT_MODE_REJECTED, actionTypes.WEBSOCKET_SET_PRT_MODE_RECEIVED, 'Mode change', actionTypes.CLEAR_PENDING_STATE);
export var pendingHolidayMode = pendingGenerator(actionTypes.SET_HOLIDAY_MODE_STARTED, actionTypes.WEBSOCKET_SET_HOLIDAY_MODE_REJECTED, actionTypes.WEBSOCKET_SET_HOLIDAY_MODE_RECEIVED, 'Holiday mode', actionTypes.CLEAR_PENDING_STATE);
export var pendingPublish = pendingGenerator(actionTypes.WEBSOCKET_PUBLISH_STARTED, actionTypes.WEBSOCKET_PUBLISH_REJECTED, actionTypes.WEBSOCKET_PUBLISH_RECEIVED, 'Publish');
export default {
  pendingSetpoint: pendingSetpoint,
  pendingMode: pendingMode,
  pendingHolidayMode: pendingHolidayMode,
  pendingPublish: pendingPublish
};