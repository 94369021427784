import React from 'react';
import { connect } from 'react-redux';
import uuid from 'uuid/v4';
import { Auth } from 'aws-amplify';

import { actions, selectors, webSocketMessages as messages } from 'darwin_core';
import {
  AUTO_MODE,
  MANUAL_MODE,
  OFF_MODE,
} from 'darwin_core/utils/consts';
import '../App.css';

import Button from '../components/common/Button';
import Modal from '../components/common/Modal';
import ErrorDisplay from '../components/ErrorDisplay';
import PlusHoursOptions from '../components/common/PlusHoursOptions';
import HomeSelector from '../components/HomeSelector';
import SetOverride from '../components/SetOverride';
import OverrideOptions from '../components/OverrideOptions';
import WebSocketDisconnected from '../components/WebSocketDisconnected';

const setMode = ({ sendWsMessage, setMode: setModeAction }, homeId, zoneId, mode) => {
  const requestId = uuid();
  setModeAction(zoneId, requestId, mode);
  sendWsMessage(messages.setMode(homeId, zoneId, requestId, mode));
};

const setPlusHours = ({ sendWsMessage, setPlusHours: setPlusHoursAction }, prtId, setpoint, duration) => {
  const requestId = uuid();
  setPlusHoursAction(prtId, requestId, duration);
  sendWsMessage(messages.startOverride(prtId, requestId, 'plus', setpoint, duration));
};

const cancelPlusHours = ({ sendWsMessage, cancelPlusHours: cancelPlusHoursAction }, prtId) => {
  const requestId = uuid();
  cancelPlusHoursAction(prtId, requestId);
  sendWsMessage(messages.cancelOverride(prtId, requestId));
};

const setOverride = ({ sendWsMessage, setOverride: setOverrideAction },
  prtId, setpoint, duration) => {
  const requestId = uuid();
  setOverrideAction(prtId, requestId);
  sendWsMessage(messages.startOverride(prtId, requestId, 'override', setpoint, duration));
};

export const HomeComponent = (props) => {
  const {
    email, logout, prt, temperature, flame, selectedHome, nextHome, previousHome, selectAHome,
    plusHoursSelected, showPlusHoursModal, dismissPlusHours, removeModeError, haveModeErrors, modeError,
    plusHoursActive, setOverrideSetpoint, showOverrideModal, actualSetpoint, dismissOverride, webSocketStatus,
    showDisconnectedModal, selectedZone, selectAZone, nextZone, previousZone,
  } = props;

  return (
  <div>
    <div>Ideal</div>
    <div>Darwin</div>

    <div>{email}</div>
    <Button onPress={() => { logout(Auth) }}>
      Logout
    </Button>

    <SetOverride
        selectedHome={selectedHome}
        setOverrideSetpoint={setOverrideSetpoint}
    />

    <HomeSelector
      nextHome={() => selectAHome(nextHome)}
      previousHome={() => selectAHome(previousHome)}
      selectedHome={selectedHome}
    />

    <HomeSelector
      nextHome={() => selectAZone(nextZone)}
      previousHome={() => selectAZone(previousZone)}
      selectedHome={selectedZone}
    />

    <div>
      <Button onPress={() => setMode(props, selectedHome, selectedZone, AUTO_MODE)}>
        Auto
      </Button>

      <Button onPress={() => setMode(props, selectedHome, selectedZone, MANUAL_MODE)}>
        Manual
      </Button>

      <Button onPress={() => setMode(props, selectedHome, selectedZone, OFF_MODE)}>
        Off
      </Button>

      { flame ? <div><span role="img" aria-label="Flame">🔥</span></div> : null }
      { plusHoursActive && <div><span role="img" aria-label="Plus">➕</span></div> }
    <div>Temperature: {temperature || "??"}</div>
    <div>Setpoint: {actualSetpoint}</div>
    <div>{JSON.stringify(prt)}</div>
    <div>WebSocket: {JSON.stringify(webSocketStatus)}</div>

    <Button
      onPress={() => plusHoursSelected()}
      hidden={prt.actualMode !== 'auto'}
    >
      + Hrs
    </Button>

    <Button
      onPress={() => cancelPlusHours(props, selectedHome)}
      hidden={!plusHoursActive}
    >
      Cancel + Hrs
    </Button>

    <Modal visible={showDisconnectedModal}>
      <WebSocketDisconnected />
    </Modal>

    <Modal visible={showPlusHoursModal}>
      <PlusHoursOptions
        onSelect={(duration) => setPlusHours(props, selectedHome, actualSetpoint, duration)}
        onClose={() => dismissPlusHours()}
      />
    </Modal>

    <Modal visible={showOverrideModal}>
      <OverrideOptions
        onSelect={duration => setOverride(
          props, selectedHome, actualSetpoint, duration,
        )}
        onClose={() => dismissOverride(selectedHome)}
      />
    </Modal>

    <Modal visible={haveModeErrors}>
      <ErrorDisplay error={modeError} onClose={() => removeModeError(modeError)} />
    </Modal>

  </div>
  </div>
)};

const mapStateToProps = state => {
  const selectedHome = selectors.getSelectedHome(state);
  const selectedZone = selectors.getSelectedZone(state, selectedHome);

  return {
    selectedHome,
    selectedZone,
    nextHome: selectors.nextHome(state),
    previousHome: selectors.previousHome(state),
    nextZone: selectors.nextZone(state),
    previousZone: selectors.previousZone(state),
    devices: selectors.getDeviceList(state),
    email: selectors.getCurrentEmail(state),
    prt: selectors.getCurrentMode(state, selectedZone),
    modeError: selectors.getFirstModeError(state),
    haveModeErrors: selectors.haveModeErrors(state),
    temperature: selectors.getCurrentTemperature(state, selectedZone),
    flame: selectors.getFlame(state, selectedZone),
    showPlusHoursModal: selectors.showPlusHoursModal(state),
    showOverrideModal: selectors.showOverrideModal(state, selectedHome),
    setpoint: selectors.getDesiredSetpointForDevice(state, selectedHome),
    actualSetpoint: selectors.getSetpoint(state, selectedHome),
    plusHoursActive: selectors.isPlusHoursActive(state, selectedHome),
  }
};

export default connect(mapStateToProps, { ...actions })(HomeComponent);
