/*
 * @format
 * these are not action creators
 * they are helpers for creating Halo messages for sending via websockets
 */
import { unflattenSchedule } from '../schedule/schedule';
export var hello = function hello() {
  return {
    action: 'hello'
  };
};
export var publish = function publish(homeId, requestId) {
  return {
    action: 'publish',
    data: {
      requestId: requestId,
      siId: homeId
    }
  };
};
export var setMode = function setMode(homeId, zoneId, requestId, mode, setpoint, holdDuration, deviceToken) {
  return {
    action: 'setPrtMode',
    deviceToken: deviceToken,
    data: {
      requestId: requestId,
      siId: homeId,
      prtId: zoneId[0],
      endpointId: zoneId[1],
      mode: mode,
      setpoint: setpoint,
      holdDuration: holdDuration
    }
  };
};
export var cancelOverride = function cancelOverride(homeId, zoneId, requestId, deviceToken) {
  return {
    action: 'cancelOverride',
    deviceToken: deviceToken,
    data: {
      requestId: requestId,
      siId: homeId,
      prtId: zoneId[0],
      endpointId: zoneId[1]
    }
  };
};
export var setPrtSchedule = function setPrtSchedule(siId, zoneId, requestId, schedule, deviceToken) {
  return {
    action: 'setSchedule',
    deviceToken: deviceToken,
    data: {
      siId: siId,
      prtId: zoneId[0],
      endpointId: zoneId[1],
      requestId: requestId,
      schedule: unflattenSchedule(schedule)
    }
  };
};
export var setHolidayMode = function setHolidayMode(homeId, zoneId, requestId, duration, deviceToken) {
  return {
    action: 'setHolidayMode',
    deviceToken: deviceToken,
    data: {
      siId: homeId,
      prtId: zoneId,
      requestId: requestId,
      duration: duration
    }
  };
};
export var cancelHolidayMode = function cancelHolidayMode(homeId, zoneId, requestId, deviceToken) {
  return {
    action: 'cancelHolidayMode',
    deviceToken: deviceToken,
    data: {
      siId: homeId,
      prtId: zoneId,
      requestId: requestId
    }
  };
};
export var setPrtSettings = function setPrtSettings(homeId, zoneId, configName, configValue, requestId, deviceToken) {
  return {
    action: 'setPrtSettings',
    deviceToken: deviceToken,
    data: {
      siId: homeId,
      prtId: zoneId[0],
      endpointId: zoneId[1],
      configName: configName,
      configValue: configValue,
      requestId: requestId
    }
  };
};
export var unpairDevice = function unpairDevice(zoneId, homeId, requestId, deviceToken) {
  return {
    action: 'unpairDevice',
    deviceToken: deviceToken,
    data: {
      siId: homeId,
      prtId: zoneId,
      requestId: requestId
    }
  };
};
export var setPRTCustomProperties = function setPRTCustomProperties(zoneId, homeId, requestId, newName) {
  return {
    action: 'setPrtCustomProperties',
    data: {
      requestId: requestId,
      siId: homeId,
      prtId: zoneId,
      endpointId: '5',
      properties: {
        FriendlyName: newName
      }
    }
  };
};
export var setChildLock = function setChildLock(homeId, zoneId, lockOn, requestId, deviceToken) {
  return {
    action: 'setChildLock',
    deviceToken: deviceToken,
    data: {
      requestId: requestId,
      siId: homeId,
      prtId: zoneId[0],
      endpointId: zoneId[1],
      lockOn: lockOn
    }
  };
};
export var setIdentifyMode = function setIdentifyMode(homeId, zoneId, enabled, requestId) {
  return {
    action: 'setIdentifyMode',
    data: {
      requestId: requestId,
      siId: homeId,
      prtId: zoneId[0],
      endpointId: zoneId[1],
      enabled: enabled
    }
  };
};
export var getPrtMessage = function getPrtMessage(homeId, zoneId, requestId, prtSetting, value, deviceToken) {
  var enabledState = value;

  if (typeof value === 'boolean') {
    enabledState = value ? '1' : '0';
  } else if (typeof value === 'number') {
    enabledState = value.toString();
  }

  switch (prtSetting) {
    case 'childLockOn':
      return setChildLock(homeId, zoneId, value, requestId, deviceToken);

    case 'setIdentifyMode':
      return setIdentifyMode(homeId, zoneId, value, requestId, deviceToken);

    default:
      return setPrtSettings(homeId, zoneId, prtSetting, enabledState, requestId, deviceToken);
  }
};
export var setWeatherOptimisation = function setWeatherOptimisation(homeId, enabled, requestId) {
  return {
    action: 'setWeatherOptimisation',
    data: {
      requestId: requestId,
      siId: homeId,
      prtId: '0',
      // at moment this seems to be ignored by SI.
      enabled: enabled
    }
  };
};
export var setZoneName = function setZoneName(siId, zoneId, text, requestId) {
  return {
    action: 'setZoneName',
    data: {
      requestId: requestId,
      siId: siId,
      prtId: zoneId[0],
      endpointId: zoneId[1],
      text: text
    }
  };
};