import React from 'react';

const Button = ({ onPress, children, disabled, hidden }) => {
  if (hidden) {
    return null;
  } else {
    return (
      <button disabled={disabled} onClick={() => { console.log('Clickly');  onPress();}}>
        {children}
      </button>
    );
  }
};

export default Button;
