/*
 * @format
 *
 * Functions to manage  schedules
 */
export var validDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']; // returns an array of the schedule for a single day

export var getDaySchedule = function getDaySchedule(day, schedule) {
  var thisDayIndex = schedule.findIndex(function (x) {
    return x.day === day;
  });
  return schedule[thisDayIndex].events;
}; // Compare 2 day schedule to see if they are different

function compareDaySchedules(pendingSchedule, latestSchedule) {
  var sortedPendingSchedule = pendingSchedule.sort(function (a, b) {
    return parseFloat(a.startTime) - parseFloat(b.startTime);
  });
  var sortedLatestSchedule = latestSchedule.sort(function (a, b) {
    return parseFloat(a.startTime) - parseFloat(b.startTime);
  });

  for (var index = 0; index < sortedPendingSchedule.length; index += 1) {
    if (sortedPendingSchedule[index].startTime !== sortedLatestSchedule[index].startTime || sortedPendingSchedule[index].setpoint !== sortedLatestSchedule[index].setpoint || sortedPendingSchedule[index].on !== sortedLatestSchedule[index].on) {
      return false;
    }
  }

  return true;
}

export var compareSchedule = function compareSchedule(pendingSchedule, currentSchedule) {
  var result = {
    isTheSame: true,
    numberOfDifferences: 0
  };
  validDays.forEach(function (day) {
    var pendingDay = pendingSchedule.filter(function (item) {
      return item.day === day;
    });
    var currenDay = currentSchedule.filter(function (item) {
      return item.day === day;
    });

    if (currenDay !== undefined) {
      var isTheSame = compareDaySchedules(pendingDay, currenDay);

      if (isTheSame === false) {
        result.numberOfDifferences += 1;
      }

      result.isTheSame = result.isTheSame && isTheSame;
    }
  });
  return result;
};
export var calculatePeriodsForSimpleSchedule = function calculatePeriodsForSimpleSchedule(simpleSchedule) {
  if (!simpleSchedule) {
    return null;
  }

  var nightStartInMins = simpleSchedule.nightStartTime;
  var morningStartInMins = simpleSchedule.morningStartTime;
  var newSchedule = validDays.map(function (day) {
    return [{
      day: day,
      startTime: nightStartInMins,
      setpoint: simpleSchedule.nightSetpoint
    }, {
      day: day,
      startTime: morningStartInMins,
      setpoint: simpleSchedule.morningSetpoint
    }];
  });
  return newSchedule.flat();
};