/*
 * @format
 */
export var LOGOUT_STARTED = 'Logout';
export var LOGOUT_COMPLETED = 'Logout Completed';
export var LOGOUT_FAILED = 'Logout Failed';
export var AMPLIFY_STATE_CHANGE = 'Amplify State Change';
export var SIGNUP = 'Sign up form submitted';
export var SIGNUP_SENSITIVE = 'Sign up form sensitive info';
export var SIGNUP_STARTED = 'Signup Started';
export var SIGNUP_COMPLETE = 'Signup Completed';
export var SIGNUP_FAILED = 'Signup Failed';
export var ACCEPT_TERMS_SET = 'Accept Terms & Conditions set';
export var ACCEPT_TERMS_CLEARED = 'Accept Terms & Conditions cleared';
export var ACCEPT_MARKETING_SET = 'Accept Marketing set';
export var DARWIN_REGISTRATION_REQUIRED = 'Darwin registration required';
export var DARWIN_REGISTRATION_STARTED = 'Darwin registration started';
export var DARWIN_REGISTRATION_FAILED = 'Darwin registration failed';
export var DARWIN_REGISTRATION_COMPLETED = 'Darwin registration completed';
export var DARWIN_AUTHENTICATED = 'Darwin authenticated';
export var DARWIN_AUTHENTICATION_FAILED = 'Darwin authentication failed';
export var DARWIN_FIRSTNAME_CHANGED = 'Darwin first name changed';
export var DARWIN_LASTNAME_CHANGED = 'Darwin last name changed';
export var DEVICE_REGISTRATION_SERIALNUMBER_SET = 'Device registration serial number added';
export var DEVICE_REGISTRATION_ATTEMPTS_RESET = 'Device registration failed attempts reset';
export var LIST_ALL_DEVICES_STARTED = 'List All Devices Started';
export var LIST_ALL_DEVICES_FAILED = 'List All Devices Failed';
export var LIST_ALL_DEVICES_SUCCEEDED = 'List All Devices Complete';
export var HOME_SELECTED = 'Select a main SI to receive MQTT messages from';
export var ZONE_SELECTED = 'Selected a Zone';
export var WEBSOCKET_HELLO_MESSAGE_RECEIVED = 'Received Hello Message';
export var WEBSOCKET_HELLO_MESSAGE_TIMEOUT = 'Hello message timed out';
export var WEBSOCKET_HELLO_MESSAGE_TIMEOUT_CLEARED = 'Hello message timeout cleared';
export var WEBSOCKET_SET_PRT_MODE_RECEIVED = 'Set PRT Mode Message Received ';
export var WEBSOCKET_SET_PRT_MODE_REJECTED = 'Set PRT Mode Message Rejected';
export var WEBSOCKET_PUBLISH_STARTED = 'Get Publish Started';
export var WEBSOCKET_PUBLISH_REJECTED = 'Get Publish Rejected';
export var WEBSOCKET_PUBLISH_RECEIVED = 'Get Publish Received';
export var WEBSOCKET_LIVE_RECEIVED = 'Received Live Message';
export var WEBSOCKET_PRT_LIVE_RECEIVED = 'Received PRT Live Message';
export var WEBSOCKET_SET_OVERRIDE_RECEIVED = 'Received Set Override Message';
export var WEBSOCKET_SET_OVERRIDE_REJECTED = 'Rejected Set Override Message';
export var WEBSOCKET_CANCEL_OVERRIDE_RECEIVED = 'Received Clear Override Message';
export var WEBSOCKET_CANCEL_OVERRIDE_REJECTED = 'Rejected Clear Override Message';
export var WEBSOCKET_MESSAGE_RECEIVED = 'Received websocket message';
export var WEBSOCKET_OPENED = 'Websocket Opened';
export var WEBSOCKET_CLOSED = 'Websocket Closed';
export var PENDING_CHANGE_TIMED_OUT = 'Pending change timed out';
export var SET_MODE_STARTED = 'Set Mode Started';
export var SET_MODE_SEND = 'Set Mode Send';
export var ADD_MODE_ERROR = 'Add Mode Error';
export var REMOVE_MODE_ERROR = 'Remove Mode Error';
export var PLUS_HOURS_SELECTED = 'Plus Hours Selected';
export var PLUS_HOURS_CONFIRMED = 'Plus Hours Confirmed';
export var PLUS_HOURS_CANCELLED = 'Plus Hours Cancelled';
export var PLUS_HOURS_DISMISSED = 'Plus Hours Dismissed';
export var SET_SETPOINT_STARTED = 'Set Setpoint Started';
export var SET_SETPOINT_SEND = 'Set Setpoint Send';
export var WEBSOCKET_SET_SETPOINT_RECEIVED = 'Received Set Setpoint Message';
export var WEBSOCKET_SET_SETPOINT_REJECTED = 'Rejected Set Setpoint Message';
export var OVERRIDE_DISMISSED = 'Override Dismissed';
export var CLEAR_OVERRIDE_COMMANDS = 'Clear Override Commands';
export var SCHEDULE_DAY_SET = 'Schedule Day Set';
export var CLEAR_SCHEDULE_UPDATE_REQUEST = 'Clear Schedule Update Request';
export var SET_SCHEDULE_STARTED = 'Set Schedule Started';
export var SET_SCHEDULE_TIMED_OUT = 'Set Schedule Timed Out';
export var WEBSOCKET_SET_SCHEDULE_ACCEPTED = 'Received Set Schedule Accepted Message';
export var WEBSOCKET_SET_SCHEDULE_REJECTED = 'Rejected Set Schedule Message';
export var DISMISS_ERROR = 'Dismiss error';
export var SET_HOLIDAY_SEND = 'Set Holiday Send';
export var SET_HOLIDAY_MODE_STARTED = 'Set Holiday Mode Started';
export var SET_HOLIDAY_CONFIRMATION_PENDING = 'Set Holiday Mode Pending';
export var SET_HOLIDAY_CONFIRMATION_FAILED = 'Set Holiday Confirmation Failed';
export var SET_HOLIDAY_CONFIRMATION_CLEAR = 'Set Holiday Confirmation Clear';
export var WEBSOCKET_SET_HOLIDAY_MODE_REJECTED = 'Set Holiday Mode Rejected';
export var WEBSOCKET_SET_HOLIDAY_MODE_RECEIVED = 'Set Holiday Mode Received';
export var CANCEL_HOLIDAY_SEND = 'Cancel Holiday Send';
export var COMMAND_SENT = 'Command sent';
export var COMMAND_COMPLETED = 'Command completed';
export var CLEAR_LAST_COMPLETED_COMMAND = 'Clear Last Completed Command';
export var SI_REGISTRATION_RESET = 'SI Registration Reset';
export var SI_REGISTRATION_STARTED = 'SI Registration Started';
export var SI_REGISTRATION_COMPLETION_STARTED = 'SI Registration Completion Started';
export var SI_REGISTRATION_COMPLETION_TIMEOUT = 'SI Registration Completion Timeout';
export var REMOVE_SI_PLACEHOLDER = 'Remove SI Placeholder';
export var PRT_COPY_SETTING_STARTED = 'PRT Settings Copy Started';
export var PRT_SETTINGS_UPDATE_STARTED = 'PRT Settings Update Started';
export var WEBSOCKET_PRT_SETTINGS_UPDATE_REJECTED = 'PRT Settings Update Rejected';
export var WEBSOCKET_PRT_SETTINGS_UPDATE_RECEIVED = 'PRT Settings Update Received';
export var NOTIFICATION_RECEIVED = 'Notification Received';
export var NOTIFICATION_TOKEN_UPDATED = 'Notificaton Token Updated';
export var CUSTOM_PROPERTIES_UPDATE_STARTED = 'Custom Properties Update Started';
export var CUSTOM_PROPERTIES_UPDATE_COMPLETED = 'Custom Properties Updated';
export var CUSTOM_PROPERTIES_UPDATE_TIMEOUT = 'Custom Properties Update Timeout';
export var CUSTOM_PROPERTIES_UPDATE_RESET = 'Custom Properties Update Reset';
export var SEND_PRT_STATE_CHANGE = 'SEND_PRT_STATE_CHANGE';
export var SEND_CONTROL = 'SEND_CONTROL';
export var DEBOUNCED_SEND_CONTROL = 'Super debounce - Queue message';
export var CLEAR_TO_SEND = 'Super debounce - Clear to send';
export var RESET_QUEUE = 'Super debounce - Reset Queue';
export var NO_SENDS_IN_PROGRESS = 'Super debounce - No sends in progress';
export var CLEAR_PENDING_STATE = 'Super debounce - Clear pending state';
export var RESUME_REGISTRATION_START = 'Resume registration - start';
export var RESUME_REGISTRATION_RETURN_TO_APP = 'Resume registration - return to app';
export var RESUME_REGISTRATION_COMPLETE = 'Resume registration - complete';
export var RESUME_REGISTRATION_ABORT = 'Resume registration - abort';
export var HOMES_MESSAGE_RECEIVED = 'Received Homes Message';
export var UNPAIR_DEVICE_STARTED = 'Unpair Device Started';
export var UNPAIR_DEVICE_TIMEOUT = 'Unpair Device Timeout';
export var UNPAIR_DEVICE_COMPLETED = 'Unpair Device Completed';
export var UNPAIR_DEVICE_REJECTED = 'Unpair Device Rejected';
export var UNPAIR_DEVICE_RESET = 'Unpair Device Reset';
export var GEOLOCATION_DEVICE_ADDED = 'Geolocation Device Added';
export var GEOLOCATION_DEVICE_REMOVED = 'Geolocation Device Removed';
export var GEOLOCATION_DEVICE_UPDATED = 'Geolocation Device Updated';
export var SET_ZONES_PENDING = 'Zones Pending Set';
export var CLEAR_ZONES_PENDING = 'Zones Pending Cleared';
export var GEOLOCATION_LOCATION_CONFIRMATION_PENDING = 'Geolocation Device Confirmation Pending';
export var GEOLOCATION_LOCATION_CONFIRMATION_FAILED = 'Geolocation Device Confirmation Failed';
export var GEOLOCATION_LOCATION_CONFIRMATION_CLEAR = 'Geolocation Device Confirmation Clear';
export var PENDING_INVITES_RECEIVED = 'Pending Invites Received';
export var DISMISS_PENDING_INVITE = 'Dismiss Pending Invite';
export var PENDING_INVITE_ACCEPTED = 'Pending Invite Accepted';
export var ADD_DEVICE_PLACEHOLDER = 'Add device placeholder';
export var SI_SETTINGS_UPDATE = 'Si Settings Update';
export var WEBSOCKET_SI_SETTINGS_UPDATE_REJECTED = 'SI Settings Update Rejected';
export var WEBSOCKET_SI_SETTINGS_UPDATE_RECEIVED = 'SI Settings Update Received';
export var COPY_SCHEDULE_SEND = 'Copy Schedule Send';
export var COPY_SCHEDULE_STARTED = 'Copy Schedule Started';
export var COPY_SCHEDULE_CONFIRMED = 'Copy Schedule Confirmed';
export var COPY_SCHEDULE_TIME_OUT = 'Copy Schedule Timeout';
export var CLEAR_SCHEDULE_COPY_REQUEST = 'Copy Schedule cleared';
export var SET_SIMPLE_SCHEDULE_SEND = 'Simple Schedule Send';
export var SET_SIMPLE_SCHEDULE_STARTED = 'Simple Schedule Started';
export var SET_SIMPLE_SCHEDULE_CONFIRMED = 'Simple Schedule Confirmed';
export var SET_SIMPLE_SCHEDULE_TIME_OUT = 'Simple Schedule Timeout';
export var CLEAR_SET_SIMPLE_SCHEDULE_REQUEST = 'Simple Schedule cleared';
export var WEATHER_OPTIMISATION_STARTED = 'Weather Optimisation Started';
export var WEATHER_OPTIMISATION_TIMEOUT = 'Weather Optimisation Timeout';
export var WEATHER_OPTIMISATION_COMPLETED = 'Weather Optimisation Completed';
export var WEATHER_OPTIMISATION_RESET = 'Weather Optimisation reset';
export var SET_ZONE_NAME_STARTED = 'Set Zone Name Started';
export var SET_ZONE_NAME_TIMEOUT = 'Set Zone Name Timeout';
export var SET_ZONE_NAME_RECEIVED = 'Set Zone Name Received';
export var SET_ZONE_NAME_REJECTED = 'Set Zone Name Rejected';
export var CLEAR_ZONE_NAME_REQUEST_STATUS = 'Clear Zone Name Request Status';