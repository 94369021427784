import _toConsumableArray from "/home/circleci/src/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _defineProperty from "/home/circleci/src/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _objectWithoutProperties from "/home/circleci/src/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

/*
 * @format
 */
import * as actionTypes from '../action-types'; // requests = [
//   {
//     requestId: 'abcd-efgh-1234-5678',
//     homeId: '4cf19e00004e',
//     zoneDeviceId: 'prt-123',
//     status: STATES.pending,
//   }
// ]

export var STATES = {
  notStarted: 'notStarted',
  pending: 'pending',
  acknowledged: 'acknowledged',
  completed: 'completed',
  timeout: 'timeout',
  rejected: 'rejected'
};
export var initialState = {
  requests: []
};
export default (function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  var type = action.type,
      actionData = _objectWithoutProperties(action, ["type"]);

  switch (type) {
    case actionTypes.UNPAIR_DEVICE_RESET:
      {
        var requestId = actionData.requestId;
        return _objectSpread(_objectSpread({}, state), {}, {
          requests: _toConsumableArray(state.requests.filter(function (request) {
            return request.requestId !== requestId;
          }))
        });
      }

    case actionTypes.UNPAIR_DEVICE_STARTED:
      {
        var _requestId = actionData.requestId,
            homeId = actionData.homeId,
            zoneDeviceId = actionData.zoneDeviceId;
        return _objectSpread(_objectSpread({}, state), {}, {
          requests: [].concat(_toConsumableArray(state.requests), [{
            requestId: _requestId,
            homeId: homeId,
            zoneDeviceId: zoneDeviceId,
            status: STATES.pending
          }])
        });
      }

    case actionTypes.UNPAIR_DEVICE_COMPLETED:
      {
        var _requestId2 = action.data.requestId;
        var entry = state.requests.find(function (r) {
          return r.requestId === _requestId2;
        });

        if (entry) {
          return _objectSpread(_objectSpread({}, state), {}, {
            requests: [].concat(_toConsumableArray(state.requests.filter(function (request) {
              return request.requestId !== _requestId2;
            })), [_objectSpread(_objectSpread({}, entry), {}, {
              status: STATES.acknowledged
            })])
          });
        }

        break;
      }

    case actionTypes.WEBSOCKET_LIVE_RECEIVED:
      {
        var _homeId = actionData.data.home.id;

        var _entry = state.requests.find(function (r) {
          return r.homeId === _homeId && r.status === STATES.acknowledged;
        });

        if (_entry) {
          return _objectSpread(_objectSpread({}, state), {}, {
            requests: _toConsumableArray(state.requests.filter(function (request) {
              return request.requestId !== _entry.requestId;
            }))
          });
        }

        break;
      }

    case actionTypes.UNPAIR_DEVICE_REJECTED:
      {
        var _requestId3 = actionData.data.requestId;

        var _entry2 = state.requests.find(function (r) {
          return r.requestId === _requestId3;
        });

        if (_entry2) {
          return _objectSpread(_objectSpread({}, state), {}, {
            requests: [].concat(_toConsumableArray(state.requests.filter(function (request) {
              return request.requestId !== _requestId3;
            })), [_objectSpread(_objectSpread({}, _entry2), {}, {
              status: STATES.rejected
            })])
          });
        }

        break;
      }

    case actionTypes.UNPAIR_DEVICE_TIMEOUT:
      {
        var _requestId4 = actionData.requestId;

        var _entry3 = state.requests.find(function (r) {
          return r.requestId === _requestId4;
        }); // only time out if request is pending


        if (_entry3 && (_entry3.status === STATES.pending || _entry3.status === STATES.acknowledged)) {
          return _objectSpread(_objectSpread({}, state), {}, {
            requests: [].concat(_toConsumableArray(state.requests.filter(function (request) {
              return request.requestId !== _requestId4;
            })), [_objectSpread(_objectSpread({}, _entry3), {}, {
              status: STATES.timeout
            })])
          });
        }

        break;
      }

    default:
      return state;
  }

  return state;
});