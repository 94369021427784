import _defineProperty from "/home/circleci/src/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

/*
 * @format
 */
import * as actionTypes from '../action-types';
export var initialState = {
  request: null,
  copyScheduleRequest: null,
  simpleScheduleRequest: null
};
export var schedule = function schedule() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case actionTypes.SET_SCHEDULE_STARTED:
      return _objectSpread(_objectSpread({}, state), {}, {
        request: _objectSpread(_objectSpread({}, action), {}, {
          state: 'Pending'
        })
      });

    case actionTypes.COPY_SCHEDULE_STARTED:
      return _objectSpread(_objectSpread({}, state), {}, {
        copyScheduleRequest: _objectSpread(_objectSpread({}, action), {}, {
          copyScheduleState: 'Pending'
        })
      });

    case actionTypes.COPY_SCHEDULE_CONFIRMED:
      return _objectSpread(_objectSpread({}, state), {}, {
        copyScheduleRequest: _objectSpread(_objectSpread({}, action), {}, {
          copyScheduleState: 'Confirmed'
        })
      });

    case actionTypes.COPY_SCHEDULE_TIME_OUT:
      if (state.copyScheduleRequest && state.copyScheduleRequest.requestId === action.requestId && state.copyScheduleRequest.copyScheduleState === 'Pending') {
        return _objectSpread(_objectSpread({}, state), {}, {
          copyScheduleRequest: _objectSpread(_objectSpread({}, state.request), {}, {
            copyScheduleState: 'Timed out'
          })
        });
      }

      return state;

    case actionTypes.CLEAR_SCHEDULE_COPY_REQUEST:
      return initialState;

    case actionTypes.CLEAR_SCHEDULE_UPDATE_REQUEST:
      return initialState;

    case actionTypes.SET_SIMPLE_SCHEDULE_STARTED:
      return _objectSpread(_objectSpread({}, state), {}, {
        simpleScheduleRequest: _objectSpread(_objectSpread({}, action), {}, {
          simpleScheduleState: 'Pending'
        })
      });

    case actionTypes.SET_SIMPLE_SCHEDULE_CONFIRMED:
      return _objectSpread(_objectSpread({}, state), {}, {
        simpleScheduleRequest: _objectSpread(_objectSpread({}, action), {}, {
          simpleScheduleState: 'Confirmed'
        })
      });

    case actionTypes.SET_SIMPLE_SCHEDULE_TIME_OUT:
      if (state.simpleScheduleRequest && state.simpleScheduleRequest.requestId === action.requestId && state.simpleScheduleRequest.simpleScheduleState === 'Pending') {
        return _objectSpread(_objectSpread({}, state), {}, {
          simpleScheduleRequest: _objectSpread(_objectSpread({}, state.request), {}, {
            simpleScheduleState: 'Timed out'
          })
        });
      }

      return state;

    case actionTypes.CLEAR_SET_SIMPLE_SCHEDULE_REQUEST:
      return initialState;

    case actionTypes.SET_SCHEDULE_TIMED_OUT:
      if (state.request && state.request.requestId === action.requestId && state.request.state === 'Pending') {
        return _objectSpread(_objectSpread({}, state), {}, {
          request: _objectSpread(_objectSpread({}, state.request), {}, {
            state: 'Timed out'
          })
        });
      }

      return state;

    case actionTypes.WEBSOCKET_SET_SCHEDULE_ACCEPTED:
      // add action.data.requestId ( action.requestId) before that to support my si. xx
      if (state.request && state.request.requestId === action.data.requestId && state.request.state === 'Pending') {
        return _objectSpread(_objectSpread({}, state), {}, {
          request: _objectSpread(_objectSpread({}, state.request), {}, {
            state: 'Accepted'
          })
        });
      }

      return state;

    case actionTypes.WEBSOCKET_SET_SCHEDULE_REJECTED:
      if (state.request && state.request.requestId === action.data.requestId && state.request.state === 'Pending') {
        return _objectSpread(_objectSpread({}, state), {}, {
          request: _objectSpread(_objectSpread({}, state.request), {}, {
            state: 'Rejected'
          })
        });
      }

      return state;
    // clear request when websocket live received

    case actionTypes.WEBSOCKET_LIVE_RECEIVED:
      if (state.request && state.request.state !== 'Pending' && state.request.state !== 'Timed out') {
        return initialState;
      }

      return state;

    default:
      return state;
  }
};