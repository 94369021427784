/*
 * @format
 */

import React from 'react';

const getQueryParams = () => {
  const queryString = window.location.href.split('?')[1];
  const params = queryString.split('&');

  return params.reduce((acc, paramString) => {
    const parts = paramString.split('=');

    acc[parts[0]] = parts[1];
    return acc;
  }, {});
};

export default class RedirectToForgotPasswordDeepLink extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      code: '',
    };
  }

  componentDidMount() {
    const {email, code} = getQueryParams();
    this.setState({
      email,
      code,
    });

    const deepLink = `haloapp://AuthNav/ForgotPasswordCodeEntry?email=${email}&code=${code}`;
    console.log('Redirect to deep link password reset code entry : ');
    console.log(deepLink);

    window.location.href = deepLink;
  }

  render() {
    return (
      <div>
        <br />
        Link not working or viewing this from your computer?
        <br />
        <br />
        Open the Halo app and tap Forgot password
        <br />
        Enter your email : {decodeURIComponent(this.state.email)}
        <br />
        Tap I already have a password reset code
        <br />
        Enter the confirmation code : {this.state.code}
        <br />
        Enter and confirm your new password.
        <br />
      </div>
    );
  }
}
