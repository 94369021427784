import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import createDebounce from "redux-debounced";

import { reducers } from "darwin_core";

// Root Reducer
const rootReducer = combineReducers({
  auth: reducers.auth,
  authSensitive: reducers.authSensitive,
  gatewayManagement: reducers.gatewayManagement,
  zone: reducers.zone,
  homeList: reducers.homeList,
  schedule: reducers.schedule
});

const store = createStore(
  rootReducer,
  applyMiddleware(createDebounce(), thunk)
);

export default store;
