import _defineProperty from "/home/circleci/src/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

/*
 * @format
 */
import * as actionTypes from '../action-types';
export var initialState = {
  requestState: null,
  title: '',
  message: ''
};
export var deviceGeoStatus = function deviceGeoStatus() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case actionTypes.GEOLOCATION_LOCATION_CONFIRMATION_PENDING:
      return {
        requestState: 'Pending',
        title: 'Updating device location',
        message: "Waiting for your device to confirm it's location"
      };

    case actionTypes.GEOLOCATION_LOCATION_CONFIRMATION_FAILED:
      return {
        requestState: 'Failed',
        title: 'Waiting for Location',
        message: "Your home/away status may take a short time to appear"
      };

    case actionTypes.GEOLOCATION_LOCATION_CONFIRMATION_CLEAR:
      return _objectSpread({}, initialState);

    default:
      return state;
  }
};