import _defineProperty from "/home/circleci/src/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

// @flow
import * as actionTypes from './action-types';
/*
type State = {
  +userToken: string,
  // ...
};
*/

export var initialState = {
  helloMessageReceived: false,
  helloMessageTimeout: false,
  isAuthenticated: false,
  email: '',
  termsAndConditionsCompleted: false,
  AcceptedMarketing: false,
  AcceptedTerms: false,
  TermsAgreedAt: '',
  TermsVersion: '',
  isLoggingOut: false
};
export var auth = function auth() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    // case actionTypes.RESET_AUTH_STATE:
    //   return initialState;
    case actionTypes.LOGOUT_COMPLETED:
      return _objectSpread(_objectSpread({}, initialState), {}, {
        email: state.email
      });

    case actionTypes.AMPLIFY_STATE_CHANGE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isAuthenticated: action.isAuthenticated,
        email: action.email
      });

    case actionTypes.HOMES_MESSAGE_RECEIVED:
    case actionTypes.WEBSOCKET_HELLO_MESSAGE_RECEIVED:
      return _objectSpread(_objectSpread({}, state), {}, {
        helloMessageReceived: true,
        helloMessageTimeout: false
      });

    case actionTypes.WEBSOCKET_HELLO_MESSAGE_TIMEOUT:
      if (!state.helloMessageReceived) {
        return _objectSpread(_objectSpread({}, state), {}, {
          helloMessageTimeout: true
        });
      }

      return state;

    case actionTypes.WEBSOCKET_HELLO_MESSAGE_TIMEOUT_CLEARED:
      return _objectSpread(_objectSpread({}, state), {}, {
        helloMessageTimeout: false
      });

    case actionTypes.SIGNUP:
      return _objectSpread(_objectSpread({}, state), {}, {
        email: action.email,
        termsAndConditionsCompleted: true,
        AcceptedMarketing: action.AcceptedMarketing,
        TermsAgreedAt: action.TermsAgreedAt,
        TermsVersion: action.TermsVersion
      });

    case actionTypes.ACCEPT_TERMS_SET:
      return _objectSpread(_objectSpread({}, state), {}, {
        AcceptedTerms: action.acceptTerms,
        TermsAgreedAt: action.acceptedTermsTime,
        TermsVersion: action.termsVersion
      });

    case actionTypes.ACCEPT_TERMS_CLEARED:
      return _objectSpread(_objectSpread({}, state), {}, {
        AcceptedTerms: false,
        TermsAgreedAt: '',
        TermsVersion: ''
      });

    case actionTypes.ACCEPT_MARKETING_SET:
      return _objectSpread(_objectSpread({}, state), {}, {
        AcceptedMarketing: action.acceptMarketing
      });

    case actionTypes.LOGOUT_STARTED:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoggingOut: true
      });

    case actionTypes.LOGOUT_FAILED:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoggingOut: false
      });

    default:
      return state;
  }
};
export var authSensitive = function authSensitive() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    password: ''
  };
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case actionTypes.SIGNUP_SENSITIVE:
      return _objectSpread(_objectSpread({}, state), {}, {
        password: action.password
      });

    default:
      return state;
  }
};
export var darwinAuthInitialState = {
  isAuthenticated: false,
  authenticationFailed: false,
  registrationRequired: false,
  registrationFailed: false,
  registrationInProgress: false,
  registrationJustCompleted: false,
  pendingInviteAccepted: false,
  firstName: '',
  lastName: '',
  groups: [],
  pendingInvites: []
};
export var darwinAuth = function darwinAuth() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : darwinAuthInitialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case actionTypes.LOGOUT_COMPLETED:
      return darwinAuthInitialState;

    case actionTypes.DARWIN_REGISTRATION_REQUIRED:
      return _objectSpread(_objectSpread({}, state), {}, {
        registrationRequired: true
      });

    case actionTypes.DARWIN_REGISTRATION_STARTED:
      return _objectSpread(_objectSpread({}, state), {}, {
        isRegistering: true
      });

    case actionTypes.DARWIN_REGISTRATION_FAILED:
      return _objectSpread(_objectSpread({}, state), {}, {
        registrationFailed: true
      });

    case actionTypes.DARWIN_REGISTRATION_COMPLETED:
      return _objectSpread(_objectSpread({}, state), {}, {
        registrationFailed: false,
        registrationJustCompleted: true
      });

    case actionTypes.DARWIN_AUTHENTICATION_FAILED:
      return _objectSpread(_objectSpread({}, state), {}, {
        authenticationFailed: true
      });

    case actionTypes.DARWIN_AUTHENTICATED:
      return _objectSpread(_objectSpread({}, state), {}, {
        registrationFailed: false,
        authenticationFailed: false,
        isRegistering: false,
        isAuthenticated: true,
        registrationRequired: false,
        cognitoId: action.cognitoId,
        email: action.email,
        firstName: action.firstName,
        lastName: action.lastName,
        groups: action.groups
      });

    case actionTypes.PENDING_INVITES_RECEIVED:
      return _objectSpread(_objectSpread({}, state), {}, {
        pendingInvites: action.data.map(function (pendingInvite) {
          var _pendingInvite$attrib = pendingInvite.attributes,
              Email = _pendingInvite$attrib.Email,
              DeviceRegistrationId = _pendingInvite$attrib.DeviceRegistrationId,
              CreatedByName = _pendingInvite$attrib.CreatedByName;
          return {
            id: pendingInvite.id,
            email: Email,
            createdByName: CreatedByName,
            deviceRegistrationId: DeviceRegistrationId
          };
        })
      });

    case actionTypes.PENDING_INVITE_ACCEPTED:
      return _objectSpread(_objectSpread({}, state), {}, {
        pendingInviteAccepted: true
      });

    case actionTypes.DISMISS_PENDING_INVITE:
      return _objectSpread(_objectSpread({}, state), {}, {
        pendingInvites: state.pendingInvites.filter(function (statePendingInvite) {
          return statePendingInvite !== action.pendingInvite;
        })
      });

    case actionTypes.DARWIN_FIRSTNAME_CHANGED:
      return _objectSpread(_objectSpread({}, state), {}, {
        firstName: action.firstName
      });

    case actionTypes.DARWIN_LASTNAME_CHANGED:
      return _objectSpread(_objectSpread({}, state), {}, {
        lastName: action.lastName
      });

    default:
      return state;
  }
};
export var deviceRegistrationInitialState = {
  registrationAttempts: 0,
  serialNumber: '',
  pin: '',
  deviceRegistrationSucceeded: false,
  deviceRegistrationInProgress: false,
  deviceRegistrationError: '',
  errorTitle: '',
  deviceRegistrationInputValid: false
};

var validateSerial = function validateSerial(serialNumber) {
  return serialNumber.length > 4;
};

export var deviceRegistration = function deviceRegistration() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : deviceRegistrationInitialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case actionTypes.DEVICE_REGISTRATION_ATTEMPTS_RESET:
      return _objectSpread(_objectSpread({}, state), {}, {
        registrationAttempts: deviceRegistrationInitialState.registrationAttempts
      });

    case actionTypes.DEVICE_REGISTRATION_SERIALNUMBER_SET:
      return _objectSpread(_objectSpread({}, state), {}, {
        serialNumber: action.serialNumber,
        deviceRegistrationInputValid: validateSerial(action.serialNumber)
      });

    case actionTypes.LOGOUT_COMPLETED:
      return deviceRegistrationInitialState;

    default:
      return state;
  }
};
export var gatewayManagementInitialState = {
  gateways: [],
  listAllDevicesInProgress: false,
  listAllDevicesSucceeded: false,
  listAllDevicesErrorMessage: ''
};
export var gatewayManagement = function gatewayManagement() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : gatewayManagementInitialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case actionTypes.LIST_ALL_DEVICES_STARTED:
      return _objectSpread(_objectSpread({}, state), {}, {
        listAllDevicesInProgress: true,
        gateways: []
      });

    case actionTypes.LIST_ALL_DEVICES_FAILED:
      return _objectSpread(_objectSpread({}, state), {}, {
        listAllDevicesSucceeded: false,
        listAllDevicesInProgress: false,
        listAllDevicesErrorMessage: action.message
      });

    case actionTypes.LIST_ALL_DEVICES_SUCCEEDED:
      return _objectSpread(_objectSpread({}, state), {}, {
        listAllDevicesSucceeded: true,
        listAllDevicesInProgress: false,
        gateways: action.gateways
      });

    default:
      return state;
  }
}; // combined Root reducer now lives in store