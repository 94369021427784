import { Component } from "react";
import { Auth } from "aws-amplify";
import config from "../.env";

export default class WebSocketHandler extends Component {
  constructor(props) {
    super(props);
    this.sendWsMessage = this.sendWsMessage.bind(this);
    this.showDisconnectedModal = this.showDisconnectedModal.bind(this);
    this.retries = [];

    this.state = {
      ...this.state,
      webSocketStatus: "Setting up connection",
    };
  }

  async componentDidMount() {
    await this.startWebSocketConnection();
  }

  componentDidUpdate(prevProps, prevState) {
    const { webSocketStatus } = this.state;

    if (webSocketStatus !== prevState.webSocketStatus) {
      this.setState({ webSocketStatus }); // eslint-disable-line react/no-did-update-set-state
    }
  }

  componentWillUnmount() {
    if (this.ws) {
      this.ws.close();
    }
  }

  async startWebSocketConnection() {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    const server = `${config.darwin_websocket_api_endpoint}?token=${token}`;

    this.ws = new WebSocket(server);

    const { onMessage, onOpen } = this.props;

    this.ws.onopen = () => {
      console.log("On Open");

      this.sendWsMessage({
        action: "hello",
      });

      this.setState({ webSocketStatus: "Connected" });
      onOpen();
    };

    this.ws.onmessage = (e) => {
      console.log(e.data);
      const json = JSON.parse(e.data);
      onMessage(json);
    };

    this.ws.onclose = () => {
      console.log("On close");
      this.retryWebSocketConnection();
    };

    this.ws.onerror = () => {
      console.log("On error");
      this.retryWebSocketConnection();
    };
  }

  async retryWebSocketConnection(recursiveCall) {
    const { webSocketStatus } = this.state;

    if (this.ws.readyState !== this.ws.OPEN || this.ws.CONNECTING) {
      if (webSocketStatus !== "Retrying" || recursiveCall === true) {
        this.setState({ webSocketStatus: "Retrying" });

        try {
          console.log("Attempting to retry the webSocket connection");
          await this.startWebSocketConnection();
        } catch (err) {
          console.log("Error attempting a retry", err);
        }

        const retry = setTimeout(async () => {
          this.retryWebSocketConnection(true);
        }, 5000 * this.backOff());

        this.retries.push(retry);
      }
    } else {
      console.log("WebSocket connected, clearing pending retries");
      this.retries.forEach((retry) => {
        console.log("RETRIES: ", retry);
        clearTimeout(retry);
      });

      this.retries = [];
    }
  }

  backOff() {
    let backOff = this.retries.length;

    if (backOff >= 10) {
      backOff = 10;
    }

    return backOff;
  }

  showDisconnectedModal() {
    const { webSocketStatus } = this.state;

    if (webSocketStatus === "Retrying") {
      return true;
    }

    if (webSocketStatus === "Disconnected") {
      return true;
    }

    return false;
  }

  sendWsMessage(message) {
    if (this.ws) {
      const args = { ...message };

      console.log("Sending WS", args);
      const payload = JSON.stringify(args);

      try {
        this.ws.send(payload);
      } catch (err) {
        console.log(
          "Error sending websocket message: ",
          err,
          this.ws.readyState
        );
        this.retryWebSocketConnection();
      }
    }
  }

  render() {
    const { children } = this.props;
    const { webSocketStatus } = this.state;

    return children(this.sendWsMessage, {
      webSocketStatus,
      showDisconnectedModal: this.showDisconnectedModal(),
    });
  }
}
