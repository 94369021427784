/*
 * @format
 */
import * as actionTypes from '../action-types';
var states = {
  NOT_STARTED: 'notStarted',
  STARTED: 'started',
  PENDING: 'pending',
  TIMEOUT: 'timeout',
  COMPLETE: 'complete'
};
export var initialState = states.NOT_STARTED;
export var siRegistration = function siRegistration() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case actionTypes.SI_REGISTRATION_RESET:
      console.log('si registration RESET');
      return states.NOT_STARTED;

    case actionTypes.SI_REGISTRATION_STARTED:
      console.log('si registration STARTED');
      return states.STARTED;

    case actionTypes.SI_REGISTRATION_COMPLETION_STARTED:
      console.log('si registration PENDING');
      return states.PENDING;

    case actionTypes.WEBSOCKET_HELLO_MESSAGE_RECEIVED:
      if (state === states.PENDING) {
        console.log('si registration COMPLETE');
        return states.COMPLETE;
      }

      return state;

    case actionTypes.SI_REGISTRATION_COMPLETION_TIMEOUT:
      if (state === states.PENDING) {
        return states.TIMEOUT;
      }

      return state;

    default:
      return state;
  }
};