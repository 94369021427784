/*
 * @format
 */
export var getNotificationColour = function getNotificationColour(notificationType) {
  switch (notificationType) {
    case 'siOnline':
    case 'faultResolved':
      return 'green';

    default:
      return 'red';
  }
};
export var getNotificationRoute = function getNotificationRoute(notificationType) {
  switch (notificationType) {
    case 'deviceOffline':
    case 'siOnline':
    case 'faultRaised':
    case 'faultResolved':
    case 'lowBattery':
      return 'SystemStatus';

    default:
      return 'Zones';
  }
};