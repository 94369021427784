import _toConsumableArray from "/home/circleci/src/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _slicedToArray from "/home/circleci/src/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";

/*
 * @format
 */
import { DateTimeFormatter, ZonedDateTime, ZoneId } from '@js-joda/core';
import '@js-joda/timezone';
import { Locale } from '@js-joda/locale_en';
import moment from 'moment-timezone';
import { MINUTES_SINCE_SUNDAY, MODES } from './consts'; // this is a little fix for inlineRequires and js-joda to make it used before it's actually used

var ukLocale = Locale.UK; // eslint-disable-next-line

export var isMatchingZoneId = function isMatchingZoneId(x, y) {
  if (!Array.isArray(x) || !Array.isArray(y)) {
    return false;
  }

  return x.join('_') === y.join('_');
};
export var getTypeFriendlyName = function getTypeFriendlyName(deviceType) {
  switch (deviceType) {
    case 'prt':
      return 'Heating';

    case 'si':
      return 'Halo';

    case 'boiler':
      return 'Boiler';

    default:
      return 'Device';
  }
};
export var makeModeNameHumanized = function makeModeNameHumanized(modeName) {
  switch (modeName) {
    case MODES.Off:
      return 'Off';

    case MODES.On:
      return 'On';

    case MODES.Auto:
      return 'Auto';

    case MODES.Manual:
      return 'Manual';

    case MODES.OverrideNext:
    case MODES.OverridePlusHours:
      return 'Override';

    default:
      return 'Unknown Mode';
  }
};
export var humanizeTemperature = function humanizeTemperature(setpoint, showdegrees, showcelsius, showOff, offValue) {
  if (showOff) {
    var setpointFrost = offValue || 500;
    if (setpoint <= setpointFrost) return 'OFF';
  }

  var degreeSymbol = '';

  if (showdegrees) {
    degreeSymbol = '°';
  }

  if (showcelsius) {
    degreeSymbol += 'c';
  }

  return "".concat((setpoint / 100).toFixed(1)).concat(degreeSymbol);
};
export var humanizeDhwState = function humanizeDhwState(on) {
  return on ? 'ON' : 'OFF';
}; // convert SI time (utc) into uk time zone.
// it will auto change between gmt and bst

export var convertToSiTime = function convertToSiTime(utcTime) {
  return moment(utcTime).tz('Europe/London');
};
export var startOfSiDay = function startOfSiDay() {
  var zdt = ZonedDateTime.now(ZoneId.of('Europe/London'));
  var startLondon = zdt.withHour(0).withMinute(0).withSecond(0).withNano(0);
  return startLondon;
};
export var humanizeTime = function humanizeTime(minutesSinceMidnight) {
  var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'HH:mm';
  var startOfDay = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : startOfSiDay();
  var time;
  var startLondon = startOfDay;
  var formatter = DateTimeFormatter.ofPattern(format).withLocale(ukLocale);

  if (minutesSinceMidnight < 1440) {
    time = startLondon.withHour(Math.floor(minutesSinceMidnight / 60)).withMinute(minutesSinceMidnight % 60);
  } else {
    var nextdayMinutes = minutesSinceMidnight - 1440;
    time = startLondon.withHour(Math.floor(nextdayMinutes / 60)).withMinute(nextdayMinutes % 60);
  }

  return time.format(formatter);
};
export var humanizePlusHours = function humanizePlusHours(minutes) {
  var hour = Math.floor(minutes / 60);
  var minute = "".concat(minutes % 60).padStart(2, '0');
  return "".concat(hour, ":").concat(minute);
}; // converts minutes from midnight to H:MM am/HH:mm pm

export var humanizeTimeTwelveHour = function humanizeTimeTwelveHour(minutesSinceMidnight) {
  return humanizeTime(minutesSinceMidnight, 'h:mma');
};
export var formatTime = function formatTime(minutesSinceMidnight) {
  var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'h:mma';
  return humanizeTime(minutesSinceMidnight, format);
}; // expose moment formatting to app

export var momentFormat = function momentFormat(val, oldFormat, newFormat) {
  return moment(val, oldFormat).format(newFormat);
};
export var convert24HourTimeToMinutes = function convert24HourTimeToMinutes(timeString) {
  var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'HH:mm';

  // we're replacing the date time library version of this function and it
  // gets very messy when daylight savings become involved.
  if (!timeString || timeString.indexOf(':') < 0) {
    return NaN;
  } // this function is sometimes called when in 12 hour
  // format but needs to be 24 to calculate correctly


  var formattedTimeString = momentFormat(timeString, format, 'HH:mm');

  var _formattedTimeString$ = formattedTimeString.split(':'),
      _formattedTimeString$2 = _slicedToArray(_formattedTimeString$, 2),
      hours = _formattedTimeString$2[0],
      minutes = _formattedTimeString$2[1];

  return Number(hours) * 60 + Number(minutes.substring(0, 2));
};
export var dehumanizeTime = convert24HourTimeToMinutes; // Date Object -> "Thursday"

export var dateToLongDayString = function dateToLongDayString(date) {
  return moment(date).format('dddd');
}; // this one does datetime object to minutes past midnight

export var dateTimeToMinutesSinceMidnight = function dateTimeToMinutesSinceMidnight(dateTime) {
  var providedDateTime = moment(dateTime); // previously this used moment .startOfDay and .diff, but that is logically wrong for
  // a clock change day where there have been more or less hours than shown on clocks
  // TODO: prove that there are no cases where this needs to show more than 24 hours

  return providedDateTime.hour() * 60 + providedDateTime.minutes();
};
export var getMinutesSinceSunday = function getMinutesSinceSunday(date) {
  var currentDay = dateToLongDayString(date);
  var minutesSinceMidnight = dateTimeToMinutesSinceMidnight(date);
  var minutesSinceSunday = MINUTES_SINCE_SUNDAY[currentDay] + minutesSinceMidnight;
  return minutesSinceSunday;
};
var daysOfWeekInOrder = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
export var getFollowingDay = function getFollowingDay(dayName) {
  var index = daysOfWeekInOrder.indexOf(dayName);
  var next = (index + 1) % 7;
  return daysOfWeekInOrder[next];
};
export var getMinuteIntervals = function getMinuteIntervals(minMinutes, maxMinutes) {
  var intervals = [];
  var max = maxMinutes;

  if (minMinutes > maxMinutes) {
    max = maxMinutes + 1440;
  }

  for (var minuteCount = minMinutes; minuteCount <= max; minuteCount += 15) {
    if (minuteCount > minMinutes && minuteCount < max) {
      if (minuteCount <= 1425) {
        intervals.push(minuteCount);
      }
    }
  }

  return intervals;
};
export var dateFormatter = function dateFormatter(date, defaultValue) {
  if (!date) {
    return defaultValue;
  }

  try {
    var formattedDate = moment(date).format('DD/MM/YYYY HH:mm');
    return formattedDate !== 'Invalid date' ? formattedDate : date;
  } catch (e) {
    console.log("Date parsing went wrong", e);
    return defaultValue;
  }
};
export var getDayOfWeek = function getDayOfWeek(date) {
  var day = date.getDay();
  var currentDay = daysOfWeekInOrder[day];

  if (!currentDay) {
    throw Error('The day entered was not recognised');
  }

  return currentDay;
};
export var getPreviousDay = function getPreviousDay(dayName) {
  var index = daysOfWeekInOrder.indexOf(dayName);
  var prevIndex = (index + 6) % 7;
  return daysOfWeekInOrder[prevIndex];
};
export var zoneSort = function zoneSort(a, b) {
  var prtDiff = a.zoneId[0].localeCompare(b.zoneId[0]);

  if (!prtDiff) {
    return prtDiff;
  }

  return a.zoneId[1] - b.zoneId[1];
};
export var sortHomesById = function sortHomesById(a, b) {
  return a.id > b.id ? 1 : -1;
};
export var uppercaseName = function uppercaseName(name) {
  return name.charAt(0).toUpperCase() + name.slice(1);
};
export var getFullName = function getFullName(user) {
  if (!user.firstName || !user.lastName) {
    if (user.firstName) {
      return user.firstName.trim();
    }

    return user.email;
  }

  var firstname = uppercaseName(user.firstName).trim();
  var lastname = uppercaseName(user.lastName).trim();
  return "".concat(firstname, " ").concat(lastname);
};

var swapArrayElements = function swapArrayElements(arr, a, b) {
  var newArray = _toConsumableArray(arr);

  var temp = newArray[a];
  newArray[a] = newArray[b];
  newArray[b] = temp;
  return newArray;
};

export var getSettingsDifferences = function getSettingsDifferences(orginalSettings, newSettings) {
  if (orginalSettings) {
    var result = [];

    if (newSettings) {
      if (orginalSettings.optimisedStartEnabled !== newSettings.optimisedStartEnabled) {
        result.push({
          optimisedStartEnabled: newSettings.optimisedStartEnabled
        });
      }

      if (orginalSettings.optimisedStopEndabled !== newSettings.optimisedStopEndabled) {
        result.push({
          optimisedStopEndabled: newSettings.optimisedStopEndabled
        });
      }

      if (orginalSettings.delayedStartEnabled !== newSettings.delayedStartEnabled) {
        result.push({
          delayedStartEnabled: newSettings.delayedStartEnabled
        });
      }

      if (orginalSettings.childLockOn !== newSettings.childLockOn) {
        result.push({
          childLockOn: newSettings.childLockOn
        });
      }

      if (orginalSettings.frostProtectionSetpoint !== newSettings.frostProtectionSetpoint) {
        result.push({
          frostProtectionSetpoint: newSettings.frostProtectionSetpoint
        });
      }

      if (orginalSettings.temperatureOffset !== newSettings.temperatureOffset) {
        result.push({
          temperatureOffset: newSettings.temperatureOffset
        });
      }

      if (orginalSettings.displayToleranceDot1 !== newSettings.displayToleranceDot1) {
        result.push({
          displayToleranceDot1: newSettings.displayToleranceDot1
        });
      }

      if (orginalSettings.timeFormat !== newSettings.timeFormat) {
        result.push({
          timeFormat: newSettings.timeFormat
        });
      }
    }

    if (newSettings.optimisedStartEnabled) {
      if (orginalSettings.delayedStartEnabled) {
        var indexOfOptimisedStartEnabled = result.map(function (item) {
          return item.optimisedStartEnabled;
        }).indexOf(true);
        var indexOfDelayedStartEnabled = result.map(function (item) {
          return item.delayedStartEnabled;
        }).indexOf(false);
        result = swapArrayElements(result, indexOfOptimisedStartEnabled, indexOfDelayedStartEnabled);
      }

      if (newSettings.delayedStartEnabled) {
        if (orginalSettings.optimisedStartEnabled) {
          var _indexOfOptimisedStartEnabled = result.map(function (item) {
            return item.optimisedStartEnabled;
          }).indexOf(false);

          var _indexOfDelayedStartEnabled = result.map(function (item) {
            return item.delayedStartEnabled;
          }).indexOf(true);

          result = swapArrayElements(result, _indexOfOptimisedStartEnabled, _indexOfDelayedStartEnabled);
        }
      }
    }

    return result;
  }

  return newSettings;
};
export var getSettingFriendlyName = function getSettingFriendlyName(setting) {
  var result = '';

  switch (setting) {
    case 'optimisedStartEnabled':
      result = 'Optimised Start Enabled';
      break;

    case 'optimisedStopEndabled':
      result = 'Optimised Stop Enabled';
      break;

    case 'delayedStartEnabled':
      result = 'Delayed Start Enabled';
      break;

    case 'childLockOn':
      result = 'Child Lock On';
      break;

    case 'temperatureOffset':
      result = 'Temperature Offset';
      break;

    case 'timeFormat':
      result = 'Time Format';
      break;

    case 'frostProtectionSetpoint':
      result = 'Frost Protection Setpoint';
      break;

    default:
      break;
  }

  return result;
};
export var IsZoneInHolidayMode = function IsZoneInHolidayMode(zone) {
  return zone.holidayMode === 3;
};
/* =======
  Returns on object to destruct with the appropriate field
  for dhw and ch schedule events. See example

  const event = {
    startTime: MINUTES_SINCE_SUNDAY[s.day] + p.startTime,
    ...getScheduleEventAction(p),
  }
======= */

export var getScheduleEventAction = function getScheduleEventAction(event) {
  if (event.on !== undefined) {
    return {
      on: event.on
    };
  }

  return {
    setpoint: event.setpoint
  };
};