import React from "react";
import "./Policy.css";

export const Policy = (props) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        width: "100vw",
        maxWidth: "100%",
        overflowY: "scroll",
        backgroundColor: "#333333",
      }}
    >
      <div className="container">
        <h1 style={{ color: "#FBBC41" }}>PRIVACY POLICY</h1>
        <p style={{ marginTop: 0, color: "#fff", whiteSpace: "pre-line" }}>
          Ideal Boilers Limited (we) are committed to protecting your personal
          data and respecting your privacy.
        </p>
        <h2 style={{ color: "#FBBC41" }}>Introduction</h2>
        <p style={{ marginTop: 0, color: "#fff", whiteSpace: "pre-line" }}>
          This policy (together with our end-user licence agreement as set out
          at (EULA) and any additional terms of use incorporated by reference
          into the EULA, together our Terms of Use) applies to your use of:
        </p>
        <p style={{ marginTop: 0, color: "#fff", whiteSpace: "pre-line" }}>
          Ideal Halo mobile application software (App) available on our site or
          hosted on https://app.idealhalo.co.uk (App Site), once you have
          downloaded a copy of the App onto your mobile telephone or handheld
          device (Device).
        </p>
        <p style={{ marginTop: 0, color: "#fff", whiteSpace: "pre-line" }}>
          Any of the services accessible through the App (Services) that are
          available on the App Site or other sites of ours (Services Sites).
          This policy sets out the basis on which any personal data we collect
          from you, or that you provide to us, will be processed by us. This App
          is not intended for children and we do not knowingly collect data
          relating to children. Please read the following carefully to
          understand our practices regarding your personal data and how we will
          treat it.
        </p>
        <h2 style={{ color: "#FBBC41" }}>
          Important information and who we are
        </h2>
        <p style={{ marginTop: 0, color: "#fff", whiteSpace: "pre-line" }}>
          Ideal Boilers Limited is part of the Groupe Atlantic UK & ROI Group.
          When we mention “Ideal Boilers”, we are referring to the relevant
          company in the Group Atlantic UK & ROI Group responsible for
          processing your data which will be clear to you when you use our App.
          Ideal Boilers Limited is the controller responsible for this App.
        </p>
        <h2 style={{ color: "#FBBC41" }} sub>
          Contact details
        </h2>
        <p style={{ marginTop: 0, color: "#fff", whiteSpace: "pre-line" }}>
          Our full details are:{"\n \n"}
          Full name of legal entity: Ideal Boilers Limited{"\n \n"}
          Email address: support@idealhalo.co.uk{"\n \n"}
          Postal address: Box 103 National Avenue, Hull HU5 4JN{"\n \n"}
          Telephone number: 01482 498660{"\n \n"}
          You have the right to make a complaint at any time to the Information
          Commissioner’s Office (ICO), the UK supervisory authority for data
          protection issues [or other competent supervisory authority of an EU
          member state if the App is downloaded outside the UK].{" "}
        </p>
        <h2 style={{ color: "#FBBC41" }} sub>
          Changes to the privacy policy and your duty to inform us of changes
        </h2>
        <p style={{ marginTop: 0, color: "#fff", whiteSpace: "pre-line" }}>
          We keep our privacy policy under regular review.{"\n \n"}
          This version was last updated on 28/11/19. It may change and if it
          does, these changes will be posted on this page and, where
          appropriate, notified to you by email or when you next start the App
          or log onto one of the Services Sites. The new policy may be displayed
          on-screen and you may be required to read and accept the changes to
          continue your use of the App.{"\n \n"}
          It is important that the personal data we hold about you is accurate
          and current. Please keep us informed if your personal data changes
          during our relationship with you.
        </p>
        <h2 style={{ color: "#FBBC41" }} sub>
          Third party links
        </h2>
        <p style={{ marginTop: 0, color: "#fff", whiteSpace: "pre-line" }}>
          Our Sites may, from time to time, contain links to and from the
          websites of our partner networks, advertisers and affiliates. Please
          note that these websites and any services that may be accessible
          through them have their own privacy policies and that we do not accept
          any responsibility or liability for these policies or for any personal
          data that may be collected through these websites or services, such as
          Contact and Location Data. Please check these policies before you
          submit any personal data to these websites or use these services.
        </p>
        <h2 style={{ color: "#FBBC41" }}>The data we collect about you</h2>
        <p style={{ marginTop: 0, color: "#fff", whiteSpace: "pre-line" }}>
          We may collect, use, store and transfer different kinds of personal
          data about you as follows:{"\n \n"}
          Identity Data.{"\n \n"}
          Contact Data.{"\n \n"}
          Device Data.{"\n \n"}
          Content Data.{"\n \n"}
          Profile Data.{"\n \n"}
          Usage Data.{"\n \n"}
          Marketing and Communications Data.{"\n \n"}
          Location Data{"\n \n"}
          We explain these categories of data here at the end of this privacy
          policy.{"\n \n"}
          We also collect, use and share Aggregated Data such as statistical or
          demographic data for any purpose. Aggregated Data could be derived
          from your personal data but is not considered personal data in law as
          this data will not directly or indirectly reveal your identity. For
          example, we may aggregate your Usage Data to calculate the percentage
          of users accessing a specific App feature. However, if we combine or
          connect Aggregated Data with your personal data so that it can
          directly or indirectly identify you, we treat the combined data as
          personal data which will be used in accordance with this privacy
          policy.{"\n \n"}
          We do not collect any Special Categories of Personal Data about you
          (this includes details about your race or ethnicity, religious or
          philosophical beliefs, sex life, sexual orientation, political
          opinions, trade union membership, information about your health, and
          genetic and biometric data). Nor do we collect any information about
          criminal convictions and offences.
        </p>
        <h2 style={{ color: "#FBBC41" }}>
          How is your personal data collected?
        </h2>
        <p style={{ marginTop: 0, color: "#fff", whiteSpace: "pre-line" }}>
          We will collect and process the following data about you:{"\n \n"}
          <text style={{ color: "#FBBC41", fontWeight: "bold" }}>
            Information you give us.{" "}
          </text>
          This is information (including Identity, Contact, and Marketing and
          Communications Data) you consent to giving us about you by filling in
          forms on the App Site and the Services Sites (together Our Sites), or
          by corresponding with us (for example, by email or chat). It includes
          information you provide when you register to use the App Site,
          download or register an App, search for an App enter a competition,
          promotion or survey, and when you report a problem with an App, or any
          of Our Sites. If you contact us, we will keep a record of that
          correspondence.
          {"\n \n"}
          <text style={{ color: "#FBBC41", fontWeight: "bold" }}>
            Information we collect about you and your device.{" "}
          </text>
          Each time you visit one of Our Sites or use one of our Apps we will
          automatically collect personal data including Device, Content and
          Usage Data. We collect this data using cookies and other similar
          technologies. Please see our cookie policy [LINK] for further details.
          {"\n \n"}
          <text style={{ color: "#FBBC41", fontWeight: "bold" }}>
            Location Data.{" "}
          </text>
          We also use GPS technology to determine your current location. Some of
          our location-enabled services require your personal data for the
          feature to work. If you wish to use the particular feature, you will
          be asked to consent to your data being used for this purpose. You can
          withdraw your consent at any time by disabling Location Data in your
          settings.
          {"\n \n"}
          <text style={{ color: "#FBBC41", fontWeight: "bold" }}>
            Information we receive from other sources including third parties
            and publicly available sources.{" "}
          </text>
          We will receive personal data about you from various third parties as
          set out below:{"\n \n"}
        </p>
        <ul style={{ color: "#fff", listStyleType: "none" }}>
          <li style={{ color: "#fff", marginBottom: "8px" }}>
            Device Data from the following parties:
          </li>
          <li>
            <ul style={{ color: "#fff", listStyleType: "none" }}>
              <li style={{ color: "#fff", marginBottom: "8px" }}>
                analytics providers [such as Google based outside the EU]
              </li>
            </ul>
          </li>
          <li>Location data for weather feed information from the MetOffice</li>
        </ul>
        <p style={{ marginTop: 0, color: "#fff", whiteSpace: "pre-line" }}>
          <text style={{ color: "#FBBC41", fontWeight: "bold" }}>
            Unique application numbers.{" "}
          </text>
          When you want to install or uninstall a service containing a unique
          application number or when such a service searches for automatic
          updates, that number and information about your installation, for
          example, the type of operating system, may be sent to us.{"\n \n"}
          <text style={{ color: "#FBBC41", fontWeight: "bold" }}>
            Cookies{"\n"}
          </text>
          We use cookies and/or other tracking technologies to distinguish you
          from other users of the App, App Site, the distribution platform
          (Appstore) or Services Sites and to remember your preferences. This
          helps us to provide you with a good experience when you use the App or
          browse any of Our Sites and also allows us to improve the App and Our
          Sites. For detailed information on the cookies we use, the purposes
          for which we use them and how you can exercise your choices regarding
          our use of your cookies, see our cookie policy.
        </p>
        <h2 style={{ color: "#FBBC41" }}>How we use your personal data</h2>
        <p style={{ marginTop: 0, color: "#fff", whiteSpace: "pre-line" }}>
          We will only use your personal data when the law allows us to do so.
          Most commonly we will use your personal data in the following
          circumstances:{"\n \n"}
          Where you have consented before the processing.{"\n \n"}
          Where we need to perform a contract we are about to enter or have
          entered with you.{"\n \n"}
          Where it is necessary for our legitimate interests (or those of a
          third party) and your interests and fundamental rights do not override
          those interests.{"\n \n"}
          Where we need to comply with a legal or regulatory obligation.
          {"\n \n"}
          Please see the Glossary to find out more about the types of lawful
          basis that we will rely on to process your personal data.{"\n \n"} We
          will only send you direct marketing communications by email or text if
          we have your consent. You have the right to withdraw that consent at
          any time by contacting us.{"\n \n"} We will get your express opt-in
          consent before we share your personal data with any third party for
          marketing purposes.
        </p>
        <h2 style={{ color: "#FBBC41" }}>
          Purposes for which we will use your personal data
        </h2>
        <table
          style={{
            width: "50%",
            backgroundColor: "#333333",
            borderCollapse: "collapse",
            borderWidth: 2,
            border: "solid 1px white",
            color: "#fff",
            whiteSpace: "pre-line",
          }}
        >
          <thead className="tableStyles">
            <tr className="tableStyles">
              <th className="tableStyles">Purpose/activity</th>
              <th className="tableStyles">Type of data</th>
              <th className="tableStyles">Lawful basis for processing</th>
            </tr>
          </thead>
          <tbody className="tableStyles">
            <tr className="tableStyles">
              <td className="tableStyles">
                To install the App and register you as a new App user
              </td>
              <td className="tableStyles">
                Identity{"\n \n"}Contact{"\n \n"}Device
              </td>
              <td className="tableStyles">Your consent</td>
            </tr>
            <tr className="tableStyles">
              <td className="tableStyles">
                To manage our relationship with you including notifying you of
                changes to the App or any services
              </td>
              <td className="tableStyles">
                Identity{"\n \n"}Contact{"\n \n"}Profile{"\n \n"}Marketing and
                Communications
              </td>
              <td className="tableStyles">
                Your consent{"\n \n"}Performance of a contract with you
                {"\n \n"}Necessary for our legitimate interests (to keep records
                updated and to analyse how customers use our products/ services)
                {"\n \n"}Necessary to comply with legal obligations (to inform
                you of any changes to our terms and conditions)
              </td>
            </tr>
            <tr className="tableStyles">
              <td className="tableStyles">
                To enable you to participate in a prize draw, competition or
                complete a survey
              </td>
              <td className="tableStyles">
                Identity{"\n \n"}Contact{"\n \n"}Device{"\n \n"}Profile
                {"\n \n"}Marketing and Communications
              </td>
              <td className="tableStyles">
                Your consent{"\n \n"}Performance of a contract with you
                {"\n \n"}Necessary for our legitimate interests (to analyse how
                customers use our products/services and to develop them and grow
                our business)
              </td>
            </tr>
            <tr className="tableStyles">
              <td className="tableStyles">
                To administer and protect our business and this App including
                troubleshooting, data analysis and system testing
              </td>
              <td className="tableStyles">
                Identity{"\n \n"}Contact{"\n \n"}Device
              </td>
              <td className="tableStyles">
                Necessary for our legitimate interests (for running our
                business, provision of administration and IT services, network
                security)
              </td>
            </tr>
            <tr className="tableStyles">
              <td className="tableStyles">
                To deliver content and advertisements to you{"\n \n"}To make
                recommendations to you about goods or services which may
                interest you{"\n \n"}To measure and analyse the effectiveness of
                the advertising we serve you{"\n \n"}To monitor trends so we can
                improve the App
              </td>
              <td className="tableStyles">
                Identity{"\n \n"}Contact{"\n \n"}Device{"\n \n"}Content
                {"\n \n"}Profile{"\n \n"}Usage{"\n \n"}Marketing and
                Communications{"\n \n"}Location
              </td>
              <td className="tableStyles">
                Consent{"\n \n"}Necessary for our legitimate interests (to
                develop our products/services and grow our business)
              </td>
            </tr>
          </tbody>
        </table>

        <h2 style={{ color: "#FBBC41" }}>Disclosures of your personal data</h2>
        <p style={{ marginTop: 0, color: "#fff", whiteSpace: "pre-line" }}>
          When you consent to providing us with your personal data, we will also
          ask you for your consent to share your personal data with the third
          parties set out below for the purposes set out in the table above:
          {"\n \n"}
          Internal Third Parties as set out in the Glossary.{"\n \n"}
          External Third Parties as set out in the Glossary.{"\n \n"}
          Third parties to whom we may choose to sell, transfer or merge parts
          of our business or our assets. Alternatively, we may seek to acquire
          other businesses or merge with them. If a change happens to our
          business, then the new owners may use your personal data in the same
          way as set out in this privacy policy.
        </p>

        <h2 style={{ color: "#FBBC41" }}>International transfers</h2>
        <p style={{ marginTop: 0, color: "#fff", whiteSpace: "pre-line" }}>
          We ensure your personal data is protected by requiring all our group
          companies to follow the same rules when processing your personal data.
          These rules are called “binding corporate rules”.{"\n \n"}
          Many of our external third parties are based outside the EEA so their
          processing of your personal data will involve a transfer of data
          outside the EEA.{"\n \n"}
          Whenever we transfer your personal data out of the EEA, we ensure a
          similar degree of protection is afforded to it by ensuring at least
          one of the following safeguards is implemented:{"\n \n"}
          We will only transfer your personal data to countries that have been
          deemed to provide an adequate level of protection for personal data by
          the European Commission. For further details, see European Commission:
          Adequacy of the protection of personal data in non-EU countries.
          {"\n \n"}
          Where we use certain service providers, we may use specific contracts
          approved by the European Commission which give personal data the same
          protection it has in Europe. For further details, see European
          Commission: Model contracts for the transfer of personal data to third
          countries.{"\n \n"}
          Where we use providers based in the US, we may transfer data to them
          if they are part of the Privacy Shield which requires them to provide
          similar protection to personal data shared between Europe and the US.
          For further details, see European Commission: EU-US Privacy Shield.
          {"\n \n"}
          Please contact us if you want further information on the specific
          mechanism used by us when transferring your personal data out of the
          EEA.
        </p>

        <h2 style={{ color: "#FBBC41" }}>Data security</h2>
        <p style={{ marginTop: 0, color: "#fff", whiteSpace: "pre-line" }}>
          All information you provide to us is stored on our secure servers and
          encrypted using Secured Sockets Layer Technology (SSL). Where we have
          given you (or where you have chosen) a password that enables you to
          access certain parts of Our Sites, you are responsible for keeping
          this password confidential. We ask you not to share a password with
          anyone.
          {"\n \n"}
          Once we have received your information, we will use strict procedures
          and security features to try to prevent your personal data from being
          accidentally lost, used or accessed in an unauthorised way.{"\n \n"}
          We have put in place procedures to deal with any suspected personal
          data breach and will notify you and any applicable regulator when we
          are legally required to do so.
        </p>

        <h2 style={{ color: "#FBBC41" }}>Data retention</h2>
        <p style={{ marginTop: 0, color: "#fff", whiteSpace: "pre-line" }}>
          By law we have to keep basic information about our customers
          (including Contact, Identity, Financial and Transaction Data) for six
          years after they cease being customers for tax purposes.{"\n \n"}
          In some circumstances you can ask us to delete your data: see Your
          legal rights below for further information.{"\n \n"}
          In some circumstances we will anonymise your personal data (so that it
          can no longer be associated with you) for research or statistical
          purposes, in which case we may use this information indefinitely
          without further notice to you.{"\n \n"}
          In the event that you do not use the App for a period of 2 years then
          we will treat the account as expired and your personal data may be
          deleted.
        </p>

        <h2 style={{ color: "#FBBC41" }}>Your legal rights</h2>
        <p style={{ marginTop: 0, color: "#fff", whiteSpace: "pre-line" }}>
          Under certain circumstances you have the following rights under data
          protection laws in relation to your personal data.{"\n \n"}
          Please see 1.5 of the Glossary to find out more about these rights:
          {"\n \n"}
          Request access to your personal data.{"\n \n"}
          Request correction of your personal data.{"\n \n"}
          Request erasure of your personal data.{"\n \n"}
          Object to processing of your personal data.{"\n \n"}
          Request restriction of processing your personal data.{"\n \n"}
          Request transfer or your personal data.{"\n \n"}
          Right to withdraw consent.{"\n \n"}
          You also have the right to ask us not to continue to process your
          personal data for marketing purposes.{"\n \n"}
          You can exercise any of these rights at any time by contacting us at
          Po Box 103, National Avenue, Hull, HU5 4JN or support@idealhalo.co.uk.
        </p>
      </div>
    </div>
  );
};

export default Policy;
