import React from 'react';

import Button from './common/Button';

export default ({ error, onClose }) => {
  if (!error || !error.message) {
    return null;
  }

  return (
      <div>
        <p>{error.message}</p>
        <Button onPress={onClose}>Dismiss</Button>
      </div>
  );
};
