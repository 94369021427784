/*
 * @format
 */
import * as actionTypes from '../action-types';
var states = {
  NOT_STARTED: 'notStarted',
  OFFLINE: 'offline',
  ONLINE: 'online',
  ABORTED: 'aborted'
};
export var initialState = {
  state: states.NOT_STARTED
};
export var resumeRegistration = function resumeRegistration() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case actionTypes.RESUME_REGISTRATION_START:
      {
        return {
          state: states.OFFLINE
        };
      }

    case actionTypes.RESUME_REGISTRATION_RETURN_TO_APP:
      {
        return {
          state: states.ONLINE,
          macAddress: action.macAddress,
          publicKey: action.publicKey,
          connectionValidAfter: action.connectionValidAfter
        };
      }

    case actionTypes.RESUME_REGISTRATION_ABORT:
      {
        return {
          state: states.ABORTED
        };
      }

    case actionTypes.RESUME_REGISTRATION_COMPLETE:
      return initialState;

    default:
      return state;
  }
};